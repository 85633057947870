import { Box, IconButton, styled } from '@mui/material';
import AlertNoData from 'components/common/AlertNoData';
import ArrowDropdown from 'assets/icons/arrow-drop-down.svg?react';
import ArrowDropup from 'assets/icons/arrow-drop-up.svg?react';

export default {
    Container: styled(Box)(() => ({
        position: 'relative',
        width: '100%',
    })),
    Popover: styled(Box)(() => ({
        position: 'absolute',
        width: '100%',
        paddingX: '0.5rem',
        backgroundColor: 'white',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        borderRadius: '5px',
    })),
    PopoverOption: styled(Box)(({ theme }) => ({
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        transition: '0.2s',
        fontSize: '0.9rem',
        '&:hover': {
            background: theme.palette.grey[300],
        },
    })),
    ArrowDropdown: styled(ArrowDropdown)(({ theme }) => ({})),
    ArrowDropup: styled(ArrowDropup)(({ theme }) => ({})),
    IconButton: styled(IconButton)(({ theme }) => ({
        height: '28px',
        width: '28px',
        padding: 0,
        marginRight: '-7px',
    })),
    AlertNoData: styled(AlertNoData)(() => ({})),
    AlerNoDataWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: 'fit-content',
            margin: 'auto',
            borderRadius: '50% !important',
            '& div:nth-of-type(2)': {
                width: 'fit-content !important',
                height: 'fit-content !important',
            },
        }),
    ),
};
