import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HelpPage from './HelpPage';
import { useTranslation } from 'react-i18next';
import {
    HELP_EDUCATION_ANCHOR,
    HELP_FAQ_ANCHOR,
    HELP_GLOSSARY_ANCHOR,
    HELP_SEND_EMAIL_ANCHOR,
    HELP_CHAT_WITH_US_ANCHOR,
} from '../../../constants/appConstants';

const HelpPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const arrayLinks = [
        {
            title: t('help_education'),
            link: HELP_EDUCATION_ANCHOR,
        },
        {
            title: t('help_faq'),
            link: HELP_FAQ_ANCHOR,
        },
        {
            title: t('help_glossary'),
            link: HELP_GLOSSARY_ANCHOR,
        },
        {
            title: t('help_send_email'),
            link: HELP_SEND_EMAIL_ANCHOR,
        },
        {
            title: t('help_chat_with_us'),
            link: HELP_CHAT_WITH_US_ANCHOR,
        },
    ];

    const childProps = {
        ...props,
        t,
        arrayLinks,
    };

    return <HelpPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HelpPageContainer.propTypes = propTypes;

export default HelpPageContainer;
