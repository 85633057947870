import * as React from 'react';
import { Box, DialogContent, Typography } from '@mui/material';
import CloseIcon from 'assets/icons/close-line.svg?react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentModal.styled';
import CustomIconCreator from 'components/common/CustomIconCreator';
import ActionButton from 'components/common/ActionButton';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import TopHeader from 'components/common/TopHeader';
import { CURRENCIES, TERMS, VALID_INSTRUMENT_TYPES } from 'constants/appConstants';
import { LockedFunctionalityNames } from 'types/api/rules.types';
import CustomLoader from 'components/common/CustomLoader';
import {
    convertCurrencyFormat,
    formatPercentage,
    getResultColor,
} from 'utils/helpers/commonHelper';
import USDFieldsContent from './USDFieldsContent';

const InstrumentModal = (props: Props) => {
    const {
        t,
        term,
        currency,
        openModal,
        usdTicker,
        isLoading,
        available,
        usdcTicker,
        handleClose,
        handleTermChange,
        canShowValuation,
        selectedInstrument,
        isLockedByUserType,
        handleCurrencyChange,
        handleInstrumentViewSheet,
    } = props;

    const todayVariation = selectedInstrument?.price?.value - selectedInstrument?.ppc;
    const titleInverted = ['CERTIFICATE', 'BOND', 'EQUITY', 'CERTIFICATE'].includes(
        selectedInstrument?.type,
    );
    const noVariations = ['EXTERIOR', 'ADR', 'EXTERIOR_FUND', 'EXTERIOR_BOND', 'OTHER'].includes(
        selectedInstrument?.type,
    );

    return (
        <St.Dialog open={openModal} onClose={handleClose} maxWidth={'lg'} disableScrollLock={true}>
            <St.DialogTitle>
                <TopHeader title={selectedInstrument?.type.toUpperCase()} />
                <Typography fontSize={'0.95rem'}>
                    {t('instrument_portfolio_holdings')}: {selectedInstrument?.holdingPercentage}%
                </Typography>
                <St.IconButton aria-label="close" onClick={handleClose}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </St.IconButton>
            </St.DialogTitle>
            <St.Divider />
            <DialogContent>
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    <>
                        <St.BlueText variant="h5">
                            {titleInverted
                                ? selectedInstrument.title || selectedInstrument.ticker
                                : selectedInstrument.currency || selectedInstrument.description}
                        </St.BlueText>
                        <Typography marginBottom="15px" variant="h6">
                            {titleInverted
                                ? selectedInstrument.currency || selectedInstrument.description
                                : selectedInstrument.title || selectedInstrument.ticker}
                        </Typography>
                        <St.BlueText variant="h6">{t('instrument_modal_quantity')}</St.BlueText>
                        <St.ValuesContainer>
                            <St.ValuesColumn>
                                <Typography fontSize="0.95rem">
                                    {t('instrument_modal_total')}
                                </Typography>
                                <Typography fontSize="0.95rem">
                                    {selectedInstrument?.quantity}
                                </Typography>
                            </St.ValuesColumn>
                            <St.ValuesColumn>
                                <Typography fontSize="0.95rem">
                                    {t('instrument_modal_available')}
                                </Typography>
                                <Typography fontSize="0.95rem">
                                    {selectedInstrument?.available}
                                </Typography>
                            </St.ValuesColumn>
                            <St.ValuesColumn>
                                <Typography fontSize="0.95rem">
                                    {t('instrument_modal_not_available')}
                                </Typography>
                                <Typography fontSize="0.95rem">
                                    {selectedInstrument?.notAvailable}
                                </Typography>
                            </St.ValuesColumn>
                        </St.ValuesContainer>
                        <St.ConteinerText>
                            <St.BlueText variant="h6">{t('instrument_modal_price')}</St.BlueText>
                            <St.ValuesContainer>
                                <St.ValuesColumn>
                                    <Typography fontSize="0.95rem">
                                        {t('instrument_current')}
                                    </Typography>
                                    {convertCurrencyFormat(selectedInstrument.price.value)}
                                </St.ValuesColumn>
                                {!noVariations && (
                                    <St.ValuesColumn>
                                        <Typography fontSize="0.95rem">
                                            {t('instrument_yesterday_variation')}
                                        </Typography>
                                        <Typography
                                            fontSize="0.95rem"
                                            color={getResultColor(
                                                selectedInstrument.priceVariation,
                                            )}>
                                            {convertCurrencyFormat(
                                                selectedInstrument?.priceVariation || 0,
                                            )}{' '}
                                            (
                                            {formatPercentage(
                                                selectedInstrument.priceVariationPercentage || 0,
                                            )}
                                            )
                                        </Typography>
                                    </St.ValuesColumn>
                                )}
                            </St.ValuesContainer>
                        </St.ConteinerText>

                        <St.ConteinerText>
                            <St.BlueText variant="h6">{t('instrument_valuation')}</St.BlueText>
                            <St.ValuesContainer>
                                <St.ValuesColumn>
                                    <Typography fontSize="0.95rem">
                                        {t('instrument_current')}
                                    </Typography>
                                    {convertCurrencyFormat(selectedInstrument.valuation)}
                                </St.ValuesColumn>

                                {!noVariations && (
                                    <St.ValuesColumn>
                                        <Typography fontSize="0.95rem">
                                            {t('instrument_yesterday_variation')}
                                        </Typography>
                                        <Typography
                                            fontSize="0.95rem"
                                            color={getResultColor(
                                                selectedInstrument.valuationVariation,
                                            )}>
                                            {convertCurrencyFormat(
                                                selectedInstrument?.valuationVariation || 0,
                                            )}{' '}
                                            (
                                            {formatPercentage(
                                                selectedInstrument.valuationVariationPercentage ||
                                                    0,
                                            )}
                                            )
                                        </Typography>
                                    </St.ValuesColumn>
                                )}
                            </St.ValuesContainer>
                        </St.ConteinerText>

                        {selectedInstrument.ppc !== 0 && selectedInstrument.ppc !== undefined && (
                            <>
                                <St.ConteinerText>
                                    <St.BlueText variant="h6">
                                        {t('instrument_performance')}
                                    </St.BlueText>
                                    <St.ValuesContainer>
                                        <St.ValuesColumn>
                                            <Typography fontSize="0.95rem">
                                                {t('instrument_average_purchase_price')}
                                            </Typography>
                                            <Typography fontSize={'0.95rem'}>
                                                {convertCurrencyFormat(
                                                    selectedInstrument?.ppc || 0,
                                                )}
                                            </Typography>
                                        </St.ValuesColumn>
                                        <St.ValuesColumn>
                                            <Typography fontSize="0.95rem">
                                                {t('instrument_today_price_variation')}
                                            </Typography>
                                            <Typography
                                                fontSize={'0.95rem'}
                                                color={getResultColor(todayVariation)}>
                                                {convertCurrencyFormat(todayVariation)} (
                                                {formatPercentage(selectedInstrument.ppcPercentage)}
                                                )
                                            </Typography>
                                        </St.ValuesColumn>
                                        <St.ValuesColumn>
                                            <Typography fontSize="0.95rem">
                                                {t('instrument_result')}
                                            </Typography>
                                            <Typography
                                                fontSize="0.95rem"
                                                color={getResultColor(selectedInstrument.result)}>
                                                {convertCurrencyFormat(selectedInstrument.result)}
                                            </Typography>
                                        </St.ValuesColumn>
                                    </St.ValuesContainer>
                                </St.ConteinerText>
                            </>
                        )}

                        {usdcTicker && available && canShowValuation && (
                            <USDFieldsContent
                                ticker={usdcTicker}
                                currencyType="USD-C"
                                available={available}
                                instrumentType={selectedInstrument.type}
                            />
                        )}
                        {usdTicker && available && canShowValuation && (
                            <USDFieldsContent
                                ticker={usdTicker}
                                currencyType="USD"
                                available={available}
                                instrumentType={selectedInstrument.type}
                            />
                        )}

                        {!isLockedByUserType('MARKET') &&
                            VALID_INSTRUMENT_TYPES.includes(selectedInstrument.type) && (
                                <>
                                    <St.DashedDivider />
                                    {selectedInstrument.type !== 'FUND' && (
                                        <>
                                            <Typography fontSize={'0.95rem'} paddingBottom={'20px'}>
                                                {t('instrument_select_instrument')}
                                            </Typography>
                                            <Box display="flex" justifyContent="center" gap={2}>
                                                <Box flex={1}>
                                                    <CustomAutoComplete
                                                        id="term"
                                                        value={term}
                                                        onChange={handleTermChange}
                                                        options={TERMS ?? []}
                                                        label={t('instrument_term') as string}
                                                        placeholder={
                                                            t('term_placeholder') as string
                                                        }
                                                        renderOption={(props, option) => (
                                                            <Box {...props} key={option.id}>
                                                                {option.name}
                                                            </Box>
                                                        )}
                                                    />
                                                </Box>
                                                <Box flex={1}>
                                                    <CustomAutoComplete
                                                        id="currency"
                                                        value={currency}
                                                        onChange={handleCurrencyChange}
                                                        options={
                                                            CURRENCIES
                                                                ? selectedInstrument.type ===
                                                                  'EQUITY'
                                                                    ? CURRENCIES.slice(0, 1)
                                                                    : CURRENCIES
                                                                : []
                                                        }
                                                        label={t('instrument_currency') as string}
                                                        placeholder={
                                                            t('currency_placeholder') as string
                                                        }
                                                        renderOption={(props, option) => (
                                                            <Box {...props} key={option.id}>
                                                                {option.name}
                                                            </Box>
                                                        )}
                                                    />
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                    <Box display="flex" justifyContent="center">
                                        <ActionButton
                                            variant="contained"
                                            sx={{ cursor: 'pointer' }}
                                            disabled={!currency || !term}
                                            onClick={handleInstrumentViewSheet}>
                                            {t('instrument_view_sheet').toUpperCase()}
                                        </ActionButton>
                                    </Box>
                                </>
                            )}
                    </>
                )}
            </DialogContent>
        </St.Dialog>
    );
};
const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    canShowValuation: PropTypes.bool.isRequired,
};

interface extraProps {
    openModal: boolean;
    handleClose: () => void;
    selectedInstrument: any;
    term: any;
    currency: any;
    handleTermChange: (event: React.SyntheticEvent, newValue: any) => void;
    handleCurrencyChange: (event: React.SyntheticEvent, newValue: any) => void;
    handleInstrumentViewSheet: () => void;
    isLockedByUserType: (lockedType: LockedFunctionalityNames) => boolean;
    usdTicker?: string;
    usdcTicker?: string;
    available?: number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentModal.prototype = propTypes;

export default InstrumentModal;
