import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ProfileBankAccountPage.styled';
import BankAccountCard from './BankAccountCard';
import { AlertColor, Box, IconButton, Modal, Typography } from '@mui/material';
import PlusIcon from '../../../assets/icons/plus-icon.svg?react';
import CloseIcon from '../../../assets/icons/close-line.svg?react';
import {
    BankAccountTypeOptions,
    GetBankAccountsItem,
    GetBankAccountsResponse,
} from 'types/api/cashflow.types';
import BankAccountFormContainer from './BankAccountForm/BankAccountForm.container';
import ActionButton from 'components/common/ActionButton';
import CustomLoader from 'components/common/CustomLoader';
import { DELETED, PENDING_DELETE } from 'constants/appConstants';

const ProfileBankAccountPage = (props: Props) => {
    const {
        bankAccounts,
        t,
        selectedAccount,
        setSelectedAccount,
        isEdit,
        setIsEdit,
        deleteBankAccount,
        openDeleteModal,
        setOpenDeleteModal,
        accountTypeOptions,
        SnackBar,
        isLoading,
        deleteIsLoading,
        isOpen,
        open,
        close,
        setSnackBarMessage,
    } = props;

    return (
        <>
            <St.Container component={'section'}>
                <St.SectionWrapper>
                    <Typography color="#2E4665" variant="h5" sx={{ marginLeft: '-20px' }}>
                        {t('bank_account_registered')}
                    </Typography>
                </St.SectionWrapper>
                <St.CreateCardContainer>
                    <Typography variant="h6" color="#307FDD">
                        {t('add_bank_account')}
                    </Typography>
                    <St.CustomIconButton
                        onClick={() => {
                            open();
                            setSelectedAccount(null);
                            setIsEdit(false);
                        }}>
                        <PlusIcon fontSize={'15px'} color="#307FDD" />
                    </St.CustomIconButton>
                </St.CreateCardContainer>
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    bankAccounts?.items
                        ?.filter(x => x.status != PENDING_DELETE && x.status != DELETED)
                        .map((account: GetBankAccountsItem) => {
                            return (
                                <BankAccountCard
                                    key={account.id}
                                    account={account}
                                    setSelectedAccount={setSelectedAccount}
                                    setIsEdit={setIsEdit}
                                    open={open}
                                    setOpenDeleteModal={setOpenDeleteModal}
                                />
                            );
                        })
                )}
            </St.Container>
            <Modal
                open={isOpen}
                onClose={() => {
                    setSelectedAccount(null);
                    setIsEdit(false);
                    close();
                }}>
                <St.BoxModal>
                    <IconButton
                        onClick={close}
                        sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                        <CloseIcon fontSize={'15px'} color="#307FDD" />
                    </IconButton>
                    <Typography variant="h6">
                        {isEdit
                            ? t('bank_account_title_form_edit')
                            : t('bank_account_title_form_create')}
                    </Typography>
                    <BankAccountFormContainer
                        selectedAccount={selectedAccount}
                        isEdit={isEdit}
                        close={close}
                        setSnackBarMessage={setSnackBarMessage}
                    />
                </St.BoxModal>
            </Modal>
            <Modal
                open={openDeleteModal}
                onClose={() => {
                    setOpenDeleteModal(false);
                    setSelectedAccount(null);
                }}>
                <St.BoxModal>
                    <IconButton
                        onClick={() => {
                            setOpenDeleteModal(false);
                            setSelectedAccount(null);
                        }}
                        sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                        <CloseIcon fontSize={'15px'} color="#307FDD" />
                    </IconButton>
                    <Typography variant="h6" color="#000" textAlign={'center'}>
                        {t('bank_account_delete_title')}
                    </Typography>
                    {selectedAccount && (
                        <Box>
                            <St.CardContainer>
                                <St.ContainerInfo>
                                    <Typography variant="h6" color="#307FDD">
                                        {t('bank_account_nick_name_title')}:{' '}
                                        {selectedAccount.description}
                                    </Typography>
                                    <Typography variant="h6" color="#307FDD">
                                        {selectedAccount.bankName}
                                    </Typography>
                                    <Typography variant="body2" color="#2e4665">
                                        {selectedAccount.currency === 'USD'
                                            ? 'US$ - Dólares'
                                            : 'AR$ - Pesos'}
                                    </Typography>
                                    <Typography variant="body2" color="#2e4665">
                                        {accountTypeOptions[selectedAccount.type]}
                                    </Typography>
                                    <Typography variant="body2" color="#2e4665">
                                        {t('bank_account_account_number_title')}:{' '}
                                        {selectedAccount.accountNumber}
                                    </Typography>
                                </St.ContainerInfo>
                            </St.CardContainer>
                        </Box>
                    )}

                    <ActionButton
                        variant="contained"
                        sx={{ width: '100%', marginTop: '20px' }}
                        loading={deleteIsLoading}
                        onClick={() => {
                            if (selectedAccount) {
                                deleteBankAccount(selectedAccount?.id);
                            }
                        }}>
                        {t('bank_account_delete_button')}
                    </ActionButton>
                </St.BoxModal>
            </Modal>
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    bankAccounts: GetBankAccountsResponse | undefined;
    selectedAccount: GetBankAccountsItem | null;
    setSelectedAccount: React.Dispatch<React.SetStateAction<GetBankAccountsItem | null>>;
    isEdit: boolean;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    deleteBankAccount: (id: string) => void;
    openDeleteModal: boolean;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    accountTypeOptions: BankAccountTypeOptions;
    SnackBar: () => JSX.Element;
    isLoading: boolean;
    deleteIsLoading: boolean;
    isOpen: boolean;
    open(): void;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileBankAccountPage.propTypes = propTypes;

export default ProfileBankAccountPage;
