import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ClientInformation.styled';
import { Grid } from '@mui/material';
import { tCommon } from 'constants/appConstants';
import { MarketAccount } from 'types/api/marketAccount.types';

const ClientInformation = (props: Props) => {
    const { t, selectedAccount } = props;

    return (
        <St.InfoContainer>
            <St.InfoTitle variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('client_name', {
                    clientName: `${selectedAccount?.name} ${selectedAccount?.last_name}`,
                }).toUpperCase()}
            </St.InfoTitle>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <St.InfoSection>
                        <St.InfoSubtitle sx={{ fontWeight: 'bold' }}>
                            {t('customer_code', tCommon)}:
                        </St.InfoSubtitle>
                        <St.InfoContent variant="body2">
                            {selectedAccount?.customerCode}
                        </St.InfoContent>
                    </St.InfoSection>
                    <St.InfoSection>
                        <St.InfoSubtitle sx={{ fontWeight: 'bold' }}>
                            {t('is_qualified', tCommon)}
                        </St.InfoSubtitle>
                        <St.InfoContent variant="body2">
                            {selectedAccount?.is_qualified ? 'Sí' : 'No'}
                        </St.InfoContent>
                    </St.InfoSection>
                </Grid>
                <Grid item xs={12} md={6}>
                    <St.InfoSection>
                        <St.InfoSubtitle sx={{ fontWeight: 'bold' }}>
                            {t('client_stock_exchange_tariff')}
                        </St.InfoSubtitle>
                        <St.InfoContent variant="body2">
                            {selectedAccount?.tariff_sector}
                        </St.InfoContent>
                    </St.InfoSection>
                    <St.InfoSection>
                        <St.InfoSubtitle sx={{ fontWeight: 'bold' }}>
                            {t('email', tCommon)}:
                        </St.InfoSubtitle>
                        <St.InfoContent variant="body2">{selectedAccount?.email}</St.InfoContent>
                    </St.InfoSection>
                </Grid>
            </Grid>
        </St.InfoContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    selectedAccount: PropTypes.object,
};

interface extraProps {
    selectedAccount: MarketAccount | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientInformation.propTypes = propTypes;

export default ClientInformation;
