import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MarketContext } from 'context/market.context';
import { PricesContext } from 'context/prices.context';
import {
    useGetInstrumentByTicker,
    useGetInstruments,
    useGetQuotations,
} from 'hooks/api/instruments.hooks';
import { WSTable } from 'types/context/prices.context.types';
import {
    BidsOffersChangeValues,
    InstrumentPricesDetail,
} from 'types/pages/marketInstrumentDetail.types';
import {
    getBidsOffersChangedValues,
    getInstrumentDetailInfoAdapter,
    getLastTickerQuotationForHome,
    getLastTickerQuotationForTable,
    getPricesQuotations,
} from 'utils/helpers/pricesHelper';
import { InstrumentObj } from 'types/common/instrument.types';
import { instrumentsQuotationsAdapter } from 'adapters/api/instruments.adapter';
import { useGetPreviewMaxOperable } from 'hooks/api/orders.hooks';
import { GetMarketPreviewRequest } from 'types/api/orders.types';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { Currency } from 'types/common/general.types';
import { AuthContext } from 'context/auth.context';

const getUpdatedValues = (
    prevValues: WSTable[] | undefined,
    updatedValues: WSTable[] | undefined,
): { [ticker: string]: Partial<Record<keyof WSTable, string>> } => {
    if (!prevValues || !updatedValues) {
        return {};
    }
    const prevMap = new Map<string, WSTable>(prevValues.map(item => [item.ticker, item]));
    const updates: { [ticker: string]: Partial<Record<keyof WSTable, string>> } = {};
    updatedValues.forEach(current => {
        const prev = prevMap.get(current.ticker);
        if (prev) {
            const diff: Partial<Record<keyof WSTable, string>> = {};
            Object.keys(current).forEach(key => {
                const valueKey = key as keyof WSTable;
                const currentValue = current[valueKey];
                const prevValue = prev[valueKey];
                if (currentValue !== prevValue) {
                    let diffValue: string;
                    if (currentValue === null || prevValue === null) {
                        diffValue = currentValue === null ? 'null' : currentValue.toString();
                    } else if (typeof currentValue === 'number' && typeof prevValue === 'number') {
                        diffValue = currentValue > prevValue ? '+' : '-';
                    } else {
                        diffValue = currentValue.toString();
                    }
                    diff[valueKey] = diffValue;
                }
            });
            if (Object.keys(diff).length > 0) {
                updates[current.ticker] = diff;
            }
        }
    });

    return updates;
};

export const useGetTableQuotations = ({
    termConn,
    typeInstrument = '',
    currency,
    filteredByType,
    favorite = false,
    ticker = '',
    name,
    liquidity,
    classOfFunds,
}: {
    termConn: 'CI' | '24hs';
    typeInstrument?: string;
    currency?: string;
    filteredByType?: string;
    favorite?: boolean;
    ticker?: string;
    name: string;
    liquidity: string;
    classOfFunds: string;
}) => {
    const { forceHome } = useContext(MarketContext);
    const {
        data: instruments,
        isLoading,
        totalSize,
        refetch,
    } = useGetInstruments(
        `${ticker && `&ticker=${ticker}`}${currency == 'ALL' ? '' : `&currency=${currency}`}&type=${typeInstrument}${name && `&name=${name}`}${filteredByType ? `&tags=${filteredByType}` : ''}&favoritesOnly=${favorite}`,
        !forceHome,
    );

    const {
        subscribe24hs,
        subscribeCI,
        unsubscribeCI,
        tickerPricesCI,
        unsubscribe24hs,
        tickerPrices24hs,
        isConnected24hs,
        isConnectedCI,
    } = useContext(PricesContext);
    const isConnected = termConn === 'CI' ? isConnectedCI : isConnected24hs;
    const tickerPrices = termConn === 'CI' ? tickerPricesCI : tickerPrices24hs;
    const subscribe = termConn === 'CI' ? subscribeCI : subscribe24hs;
    const unsubscribe = termConn === 'CI' ? unsubscribeCI : unsubscribe24hs;
    const [prevValues, setPrevValues] = useState<WSTable[] | undefined>();
    const [changeValues, setChangeValues] = useState<{
        [ticker: string]: Partial<Record<keyof WSTable, string>>;
    }>({});

    const tableValuesUpdated = useMemo(() => {
        if (!instruments || !tickerPrices) return undefined;
        return getLastTickerQuotationForTable(instruments, tickerPrices, currency);
    }, [tickerPrices, instruments, currency]);

    useEffect(() => {
        if (isLoading || !instruments || !isConnected) return;

        const subscriptionInstruments = instruments.flatMap(instrument =>
            instrument.tickers
                .filter(ticker => (currency == 'ALL' ? true : ticker.currency === currency))
                .map(el => el.ticker),
        );

        subscribe(subscriptionInstruments);

        return () => {
            unsubscribe(subscriptionInstruments);
        };
    }, [isLoading, isConnected, termConn, currency]);

    useEffect(() => {
        if (!tableValuesUpdated) return;
        const updateValues = getUpdatedValues(prevValues, tableValuesUpdated);
        if (Object.keys(updateValues).length > 0) setChangeValues(updateValues);
        setPrevValues(tableValuesUpdated);
    }, [tableValuesUpdated, prevValues]);

    return {
        tableData: tableValuesUpdated,
        isLoading,
        changeValues,
        totalSize: totalSize || 0,
        refetch,
    };
};

export const useGetHomeQuotations = () => {
    const { data: quotations, isLoading } = useGetQuotations();

    const {
        subscribeToPricesWS,
        unsubscribeToPricesWS,
        tickerPricesCI,
        tickerPrices24hs,
        isConnectedCI,
        isConnected24hs,
    } = useContext(PricesContext);

    const homeQuotations = useMemo(() => {
        if (!quotations || !tickerPrices24hs || !tickerPricesCI) return;
        return getLastTickerQuotationForHome(quotations, tickerPrices24hs, tickerPricesCI);
    }, [tickerPrices24hs, tickerPricesCI]);

    useEffect(() => {
        if (isLoading || !quotations || !isConnectedCI || !isConnected24hs) return;

        const subscriptionTickers = quotations.flatMap(quotation =>
            quotation.instrument.tickers.map(ticker => ticker.ticker),
        );

        subscribeToPricesWS(subscriptionTickers);

        return () => {
            unsubscribeToPricesWS(subscriptionTickers);
        };
    }, [quotations, isLoading, isConnectedCI, isConnected24hs]);

    return {
        quotations,
        isLoadingQuotations: isLoading,
        homeQuotations,
    };
};

export const useGetInstrumentDetail = (termConn: 'CI' | '24hs', ticker: string) => {
    const { instrument, rules, isLoading } = useGetInstrumentByTicker(ticker);
    const [prevValues, setPrevValues] = useState<InstrumentPricesDetail | undefined>();
    const [changeValues, setChangeValues] = useState<BidsOffersChangeValues>({});

    const {
        subscribe24hs,
        subscribeCI,
        unsubscribeCI,
        tickerPricesCI,
        unsubscribe24hs,
        tickerPrices24hs,
        isConnected24hs,
        isConnectedCI,
    } = useContext(PricesContext);

    const isConnected = termConn === 'CI' ? isConnectedCI : isConnected24hs;
    const tickerPrices = termConn === 'CI' ? tickerPricesCI : tickerPrices24hs;
    const subscribe = termConn === 'CI' ? subscribeCI : subscribe24hs;
    const unsubscribe = termConn === 'CI' ? unsubscribeCI : unsubscribe24hs;

    const tickerPriceInfo = useMemo(() => {
        if (!instrument || !tickerPrices) return;
        return getInstrumentDetailInfoAdapter(tickerPrices, ticker);
    }, [tickerPrices, instrument, ticker]);

    useEffect(() => {
        if (!isConnected) return;
        if (ticker) {
            subscribe([ticker]);
        }
        return () => {
            unsubscribe([ticker]);
        };
    }, [ticker, isConnected, termConn]);

    const tickerInfo = useMemo(() => {
        if (!instrument) return null;
        return instrument.tickers.find(t => t.ticker === ticker) ?? null;
    }, [ticker, instrument]);

    const isBond = useMemo(() => {
        if (!instrument) return false;
        return instrument?.type === 'BOND';
    }, [instrument]);

    const currencies = useMemo(() => {
        if (!instrument) return null;
        return instrument.tickers.map((t, index) => {
            return { id: index + 1, name: t.currency };
        });
    }, [instrument]);

    useEffect(() => {
        if (!tickerPriceInfo) return;
        const updateValues = getBidsOffersChangedValues(prevValues, tickerPriceInfo);
        if (Object.keys(updateValues).length > 0) setChangeValues(updateValues);
        setPrevValues(tickerPriceInfo);
    }, [tickerPriceInfo]);

    return {
        tickerPriceInfo,
        instrument: instrument || null,
        rules: rules || null,
        isLoading,
        currency: tickerInfo?.currency ?? 'ARS',
        favorite: tickerInfo?.favorite ?? false,
        requiresDeclaration: tickerInfo?.requiresDeclaration ?? null,
        isBond,
        currencies,
        changeValues,
    };
};

export const useGetExchangePrices = (
    termConn: 'Inmediato' | '24hs',
    currencyStart: Currency | null,
) => {
    const [instrument, setInstrument] = React.useState<InstrumentObj | null>(null);
    const { customerCode } = useContext(AuthContext);

    const {
        data: quotations,
        isLoading: isLoadingQuotations,
        errorMessage: errorMessageQuotations,
    } = useGetQuotations();

    const {
        data,
        errorMessage: errorMessagePreviewOperable,
        getMaxOperable,
        isLoading: isLoadingMaxOperable,
        reset,
    } = useGetPreviewMaxOperable();

    const {
        data: accountStatus,
        isLoading: isLoadingAccountStatus,
        errorMessage: errorAccountStatus,
    } = useGetAccountStatus();

    const {
        subscribe24hs,
        subscribeCI,
        unsubscribeCI,
        tickerPricesCI,
        unsubscribe24hs,
        tickerPrices24hs,
        isConnected24hs,
        isConnectedCI,
    } = useContext(PricesContext);

    const isConnected = termConn === 'Inmediato' ? isConnectedCI : isConnected24hs;
    const tickerPrices = termConn === 'Inmediato' ? tickerPricesCI : tickerPrices24hs;
    const subscribe = termConn === 'Inmediato' ? subscribeCI : subscribe24hs;
    const unsubscribe = termConn === 'Inmediato' ? unsubscribeCI : unsubscribe24hs;

    const mappedInstruments = React.useMemo(() => {
        if (!quotations) return null;
        return instrumentsQuotationsAdapter(quotations);
    }, [quotations]);

    const quotationsTickers: string[] | null = useMemo(() => {
        let tickers: string[] = [];
        if (!quotations) return null;
        quotations.forEach(q => {
            const tickersInstrument = q.instrument.tickers.map(t => t.ticker);
            tickers = [...tickers, ...tickersInstrument];
        });
        return tickers;
    }, [quotations]);

    useEffect(() => {
        if (!isConnected) return;
        if (!quotationsTickers) return;

        subscribe(quotationsTickers);

        return () => {
            unsubscribe(quotationsTickers);
        };
    }, [quotationsTickers, isConnected, subscribe, unsubscribe]);

    const mappedTickerPrices = useMemo(() => {
        if (!tickerPrices) return null;
        if (!quotations) return null;
        return getPricesQuotations(tickerPrices, quotations);
    }, [tickerPrices, quotations]);

    const handleInstrumentPreview = useCallback(
        (value: InstrumentObj | null) => {
            setInstrument(value);
            reset();
            if (value !== null && currencyStart !== null) {
                const selectedCurrency =
                    currencyStart === 'USD-C'
                        ? 'usdc'
                        : (currencyStart.toLowerCase() as 'ars' | 'usd' | 'usdc');
                const selectedInstrument = mappedTickerPrices?.find(t => t.ticker === value.name);

                const price = selectedInstrument
                    ? selectedInstrument[selectedCurrency]?.offer
                    : null;

                const body: GetMarketPreviewRequest = {
                    buySell: 'BUY',
                    quantity: null,
                    amount: accountStatus?.availableBalance?.operate?.t0[selectedCurrency] ?? null,
                    customerCode: customerCode ?? '',
                    term: termConn === 'Inmediato' ? 0 : 1,
                    price,
                    tickerId: value?.tickers.find(t => t.currency === currencyStart)?.id ?? 0,
                };
                getMaxOperable(body);
            }
        },
        [
            mappedTickerPrices,
            termConn,
            accountStatus,
            currencyStart,
            customerCode,
            reset,
            getMaxOperable,
        ],
    );

    return {
        instrument,
        instruments: mappedInstruments,
        isLoading: isLoadingQuotations,
        errorMessage: errorMessageQuotations,
        tickerPrices: mappedTickerPrices,
        setInstrument: handleInstrumentPreview,
        accountStatus,
        isLoadingAccountStatus,
        errorAccountStatus,
        isLoadingMaxOperable,
        errorMessagePreviewOperable,
        maxOperable: data?.gross ?? data?.net,
    };
};
