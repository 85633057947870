import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TreasuryBankAccountsPage from './TreasuryBankAccountsPage';
import { useTranslation } from 'react-i18next';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { GetBankAccountsItem } from 'types/api/cashflow.types';
import { formatSingleDate } from 'utils/helpers/dateHelper';
import { Box, Chip, Tooltip } from '@mui/material';
import { getColorByStatus, getFullName } from 'utils/helpers/commonHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import DeleteIcon from 'assets/icons/delete-bin-7-line.svg?react';
import {
    ACTIVE,
    BROKER,
    DELETED,
    EXTERIOR,
    ICON_CELL_SIZE,
    MARKET_ACCOUNT_BASE_URL,
    PENDING_ACCEPT,
    REJECTED,
} from 'constants/appConstants';
import CreateBankAccountModal from './CreateBankAccountModal';
import EditBankAccount from './EditBankAccount';
import BankAccountDetail from './BankAccountDetail';
import AddIcon from 'assets/icons/add-line.svg?react';
import EditIcon from 'assets/icons/pencil-line.svg?react';
import DeleteBankAccount from './DeleteBankAccount';
import moment from 'moment';

const TreasuryBankAccountsPageContainer = (props: Props) => {
    const { t } = useTranslation();

    const [openDetailModal, setOpenDetailModal] = React.useState<boolean>(false);
    const [selectedRow, setSelectedRow] = React.useState<GetBankAccountsItem>();
    const handleOpenDetail = (row: GetBankAccountsItem) => {
        setOpenDetailModal(true);
        setSelectedRow(row);
    };

    const columns: CommonColumn<GetBankAccountsItem>[] = [
        {
            id: 'createdAt',
            name: t('date_created'),
            selector: row => formatSingleDate(row.createdAt, 'DD-MM-YYYY'),
            sortable: true,
        },
        {
            id: 'customerCode',
            name: t('customer_code'),
            selector: ({ user }) => (
                <Tooltip title={getFullName(user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>
                        {user?.marketAccounts[0].customerCode || '-'}
                    </Box>
                </Tooltip>
            ),
            sortable: true,
        },
        {
            id: 'name',
            name: t('client'),
            selector: row =>
                row?.user?.lastName ? `${row.user.lastName}, ${row.user.name}` : row?.user?.name,
        },
        {
            id: 'type',
            name: t('type'),
            selector: row => (
                <Chip color="secondary" variant="outlined" sx={{ m: 0.625 }} label={t(row.type)} />
            ),
            sortable: true,
        },
        {
            id: 'currency',
            name: t('currency'),
            sortable: true,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
        },
    ];

    const headAction: CommonAction<GetBankAccountsItem> = {
        id: 'create_bank_account',
        Icon: () => <CustomIconCreator icon={AddIcon} sx={{ ...ICON_CELL_SIZE, mt: 0.7 }} />,
        onClick: props => <CreateBankAccountModal {...props} />,
    };

    const typeToHideEditAndDelete = [EXTERIOR, BROKER];

    const actions: CommonAction<GetBankAccountsItem>[] = [
        {
            id: 'detail_bank_account',
            Icon: () => <CustomIconCreator icon={EyeIcon} />,
            onClick: ({ row }) => handleOpenDetail(row),
        },
        {
            id: 'edit_bank_account',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={EditIcon} />,
            onClick: props => <EditBankAccount {...props} />,
            isHidden: row => typeToHideEditAndDelete.includes(row.type),
        },
        {
            id: 'delete_bank_account',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={DeleteIcon} sx={{ color: 'error' }} />,
            onClick: props => <DeleteBankAccount {...props} />,
            isHidden: row => typeToHideEditAndDelete.includes(row.type),
        },
    ];

    const bankAccountStatusOptions = [
        { id: ACTIVE, name: t(ACTIVE) },
        { id: PENDING_ACCEPT, name: t(PENDING_ACCEPT) },
        { id: REJECTED, name: t(REJECTED) },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'status',
            label: t('status'),
            type: 'select',
            query: 'status',
            options: bankAccountStatusOptions,
        },
        {
            id: 'customerCode',
            label: t('customer_code'),
            type: 'requestAutocomplete',
            query: 'customerCode',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerCode',
                optionLabel: option => option.customerCode,
                keyTofilter: 'customerCode',
            },
        },
        {
            id: 'client',
            label: t('client'),
            type: 'requestAutocomplete',
            query: 'customerName',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerFullName',
                optionLabel: option => `${option.name} ${option.last_name}`,
                keyTofilter: 'name',
                extraFilter: '&sortField=name&sort=ASC',
            },
        },
        {
            id: 'from',
            label: t('from'),
            type: 'date',
            query: 'dateFrom',
            disableFutureDaysFromN: true,
        },
        {
            id: 'to',
            label: t('to'),
            type: 'date',
            query: 'dateTo',
            disableFutureDaysFromN: true,
        },
    ];

    const defaultFilters = [
        { query: 'dateFrom', queryValue: moment().format('YYYY-MM-DD') },
        { query: 'dateTo', queryValue: moment().format('YYYY-MM-DD') },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        defaultFilters,
        actions,
        headAction,
        t,
    };

    return (
        <>
            <TreasuryBankAccountsPage {...childProps} />
            {selectedRow && (
                <BankAccountDetail
                    {...props}
                    row={selectedRow}
                    open={openDetailModal}
                    close={() => setOpenDetailModal(false)}
                />
            )}
        </>
    );
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TreasuryBankAccountsPageContainer.propTypes = propTypes;

export default TreasuryBankAccountsPageContainer;
