import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './RequestAutocomplete.styled';

const RequestAutocomplete = ({
    t,
    options,
    optionLabel,
    isLoading,
    isError,
    label,
    handleInputChange,
    handleChange,
    inputValue,
    openPopover,
    setOpenPopover,
    autocompleteRef,
}: Props) => (
    <St.Container ref={autocompleteRef}>
        <TextField
            size="small"
            fullWidth
            onChange={e => handleInputChange('', e.target.value)}
            label={label}
            error={isError}
            helperText={isError ? t('error_generic') : !inputValue && t('autocomplete_helper_text')}
            value={inputValue}
            onClick={() => (inputValue ? setOpenPopover(!openPopover) : undefined)}
            InputProps={{
                endAdornment: (
                    <St.IconButton onClick={() => setOpenPopover(!openPopover)}>
                        {openPopover ? <St.ArrowDropup /> : <St.ArrowDropdown />}
                    </St.IconButton>
                ),
            }}
        />
        {openPopover && (
            <St.Popover>
                {isLoading ? (
                    <St.PopoverOption>{t('request_autocomplete_loading_text')}</St.PopoverOption>
                ) : options.length > 0 ? (
                    options.map((option, index) => (
                        <St.PopoverOption key={index} onClick={() => handleChange(option)}>
                            {optionLabel ? optionLabel(option) : option.name}
                        </St.PopoverOption>
                    ))
                ) : (
                    <St.AlerNoDataWrapper>
                        <St.AlertNoData title={t('no_data_was_found')} />
                    </St.AlerNoDataWrapper>
                )}
            </St.Popover>
        )}
    </St.Container>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    openPopover: PropTypes.bool.isRequired,
    inputValue: PropTypes.string.isRequired,
    setOpenPopover: PropTypes.func.isRequired,
};

interface extraProps {
    options: any[];
    optionLabel?: (option: any) => string;
    isLoading: boolean;
    isError: boolean;
    label: string;
    handleChange(selectedOptions: any): void;
    handleInputChange(_: any, value: any): void;
    autocompleteRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RequestAutocomplete.propTypes = propTypes;

export default RequestAutocomplete;
