import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AlertsNotificationModal from './AlertsNotificationModal';
import { useTranslation } from 'react-i18next';
import { useLogout } from 'hooks/api/auth.hooks';
import { AletsObj } from 'types/api/alerts.types';

const AlertsNotificationModalContainer = (props: Props) => {
    const { setOpenModal, filteredAlert, errorMessage, isLoading } = props;
    const { t } = useTranslation('common');

    const { mutate: onLogout } = useLogout();

    const handleAccept = () => {
        if (filteredAlert[0].blocking) {
            onLogout();
        } else {
            localStorage.setItem('hasAcceptedAlert', 'true');
        }
        setOpenModal(false);
    };

    const childProps = {
        ...props,
        t,
        handleAccept,
        errorMessage,
        isLoading,
        setOpenModal,
        filteredAlert,
    };

    return <AlertsNotificationModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    open: boolean;
    setOpenModal: (state: boolean) => void;
    filteredAlert: AletsObj[];
    errorMessage: string | null;
    isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AlertsNotificationModalContainer.propTypes = propTypes;

export default AlertsNotificationModalContainer;
