import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';
import { VALID_VIDEO_EXTENSIONS } from 'constants/appConstants';
import ActionButton from 'components/common/ActionButton';
import { download } from 'utils/helpers/files.helper';
import { t } from 'i18next';

const VideoModal = (props: Props) => {
    const { open, close, url, title, fileUrl } = props;

    return (
        <Dialog maxWidth="xl" open={open} onClose={close}>
            <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        color: theme => theme.palette.grey[500],
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'}>
                    <video src={url} id={url} width="500" height="auto" controls>
                        {VALID_VIDEO_EXTENSIONS.map((m, index) => {
                            return <source key={index} src={m} type={`video/${m}`} />;
                        })}
                    </video>
                    {fileUrl && (
                        <ActionButton
                            variant="contained"
                            sx={{ width: '50%' }}
                            onClick={() => download(fileUrl)}>
                            {t('download_presentation_button_text')}
                        </ActionButton>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
};

interface extraProps {
    open: boolean;
    url: string;
    close: () => void;
    fileUrl: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
VideoModal.propTypes = propTypes;

export default VideoModal;
