import { Box, Dialog, DialogTitle, Divider, IconButton, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    ModalText: styled(Typography)(({ theme }) => ({
        marginTop: '1em',
        color: '#2E4665',
        fontSize: '1em',
        width: '100%',
    })) as typeof Typography,

    Dialog: styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(4),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '.MuiPaper-root': {
            width: '100%',
            maxWidth: '850px',
        },
    })),
    DialogTitle: styled(DialogTitle)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            m: 0,
            p: 2,
            pr: 1,
            position: 'relative',
            paddingRight: '10%',
        }),
    ),
    IconButton: styled(IconButton)(({ theme }) => ({
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    })),
    ValuesContainer: styled(Box)(({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '16px',
    })),
    ValuesColumn: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
    })),
    Divider: styled(Divider)(({ theme }) => ({
        width: '95%',
        alignSelf: 'center',
    })),
    BlueText: styled(Typography)(({ theme }) => ({
        color: theme.palette.primary.main,
    })),
    ConteinerText: styled(Box)(() => ({
        paddingTop: '1rem',
    })),
    ConteinerTextUSD: styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.custom.vibrantblue10,
        borderRadius: '10px',
        padding: '10px',
    })),
    DashedDivider: styled(Divider)(({ theme }) => ({
        color: theme.palette.custom.darkblue8,
        marginTop: pxToRem('40px'),
        marginBottom: pxToRem('40px'),
        borderBottom: '2px dashed',
    })),
    ButtonContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
    })),
};
