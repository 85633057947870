import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { DailyOperation } from 'types/pages/reports.types';
import { AuthContext } from 'context/auth.context';
import { tCommon, tErrorsContext } from 'constants/appConstants';
import moment from 'moment';
import CustomIconCreator from 'components/common/CustomIconCreator';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import { formatSingleDate } from 'utils/helpers/dateHelper';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';
import ReportsOperationDetailModal from './ReportsOperationDetailModal';
import ReportsDailyOperationsPage from './ReportsDailyOperationsPage';
import { Box, Tooltip } from '@mui/material';
import { useCommonGrid } from 'context/common/commonGrid.context';

const ReportsDailyOperationsPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'channel_daily_operations_';
    const { customerCode } = React.useContext(AuthContext);
    const { filterQueryString } = useCommonGrid();

    const columns: CommonColumn<DailyOperation>[] = [
        {
            id: 'customerCode',
            name: t(`${tBase}customer_code`),
            selector: row => (
                <Tooltip title={getFullName(row.user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>{row.customerCode || '-'}</Box>
                </Tooltip>
            ),
            exportOption: row => row.customerCode ?? '-',
        },
        {
            id: 'operationTypeDescription',
            name: t(`${tBase}op_type_description`),
            selector: row => row.operationTypeDescription ?? '-',
            exportOption: row => row.operationTypeDescription ?? '-',
        },
        {
            id: 'ticketNumber',
            name: t(`${tBase}ticket_number`),
            selector: row => row.ticketNumber ?? '-',
            exportOption: row => row.ticketNumber ?? '-',
        },
        {
            id: 'market',
            name: t(`${tBase}market`),
            selector: row => row.market ?? '-',
            exportOption: row => row.market ?? '-',
        },
        {
            id: 'net',
            name: t(`${tBase}net`),
            selector: row =>
                convertCurrencyFormat(row.net, row.currency === 'ARS' ? 'ARS' : 'USD') ?? '-',
            exportOption: row =>
                convertCurrencyFormat(row.net, row.currency === 'ARS' ? 'ARS' : 'USD') ?? '-',
        },
        {
            id: 'agreementDate',
            name: t(`${tBase}agreement_date`),
            selector: row => formatSingleDate(row.agreementDate) ?? '-',
            exportOption: row => formatSingleDate(row.agreementDate) ?? '-',
        },
    ];

    const actions: CommonAction<DailyOperation>[] = [
        {
            id: 'daily_operations_detail',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={EyeIcon} />,
            onClick: props => <ReportsOperationDetailModal {...props} />,
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateFrom',
            label: t('from', tCommon),
            type: 'date',
            query: 'dateFrom',
            disablePastDaysFromN: moment().subtract(8, 'days').format('YYYY/MM/DD'),
            disableFutureDaysFromN: moment().add(1, 'days').format('YYYY/MM/DD'),
            disableYears: true,
        },
        {
            id: 'dateTo',
            label: t('to', tCommon),
            type: 'date',
            query: 'dateTo',
            disablePastDaysFromN: moment().subtract(8, 'days').format('YYYY/MM/DD'),
            disableFutureDaysFromN: moment().add(1, 'days').format('YYYY/MM/DD'),
            disableYears: true,
        },
    ];

    const filterDateValidation = (
        filters: string,
    ): { result: boolean; error: string | undefined } => {
        if (
            ['', undefined].includes(filters) ||
            !filters.includes('dateFrom') ||
            !filters.includes('dateTo')
        )
            return {
                result: false,
                error: t('enter_date_from_and_to', tErrorsContext) as string,
            };

        return { result: true, error: undefined };
    };

    const current = moment();
    const lastWeek = moment().subtract(7, 'days');

    const defaultFilters = [
        { query: 'dateFrom', queryValue: lastWeek.format('YYYY-MM-DD') },
        { query: 'dateTo', queryValue: current.format('YYYY-MM-DD') },
    ];

    const parseFilterString = (queryString: string | undefined) => {
        const filters = queryString?.split('&') || [];
        const result: Record<string, string | undefined> = {};
        filters.forEach(filter => {
            const [key, value] = filter.split('=');
            result[key] = value;
        });
        return result;
    };

    const dateValues = parseFilterString(filterQueryString);
    const dateFrom = moment(dateValues.dateFrom).format('DD-MM-YYYY');
    const dateTo = moment(dateValues.dateTo).format('DD-MM-YYYY');

    const exportTitle = `${t('daily_operations_doc_title')} ${dateFrom} - ${dateTo}`;

    const childProps = {
        ...props,
        columns,
        filters,
        actions,
        customerCode,
        defaultFilters,
        filterDateValidation,
        exportTitle,
        t,
    };

    return <ReportsDailyOperationsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsDailyOperationsPageContainer.propTypes = propTypes;

export default ReportsDailyOperationsPageContainer;
