import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientInformation from './ClientInformation';
import { AuthContext } from 'context/auth.context';
import { useTranslation } from 'react-i18next';

const ClientInformationContainer = (props: Props) => {
    // const { t } = props;
    const { t } = useTranslation('home');
    const { selectedCustomer } = React.useContext(AuthContext);

    const childProps = {
        ...props,
        t,
        selectedAccount: selectedCustomer,
    };

    return <ClientInformation {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientInformationContainer.propTypes = propTypes;

export default ClientInformationContainer;
