import { Box, Typography, styled } from '@mui/material';

export default {
    BlueText: styled(Typography)(({ theme }) => ({
        color: theme.palette.primary.main,
    })),
    ContainerTextUSD: styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.custom.vibrantblue10,
        borderRadius: '10px',
        padding: '10px',
    })),
    ButtonContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
    })),
    Container: styled(Box)(({ theme }) => ({})),
};
