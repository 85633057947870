import PdfIcon from 'assets/img/general/pdfFile.svg?react';
import TXTFileIcon from 'assets/img/general/txtFile.svg?react'; /*
import DocFileIcon from 'assets/img/general/docFile.svg?react';
import JPGFileIcon from 'assets/img/general/jpgFile.svg?react'; */
import axios from 'axios';
import { tErrorsContext } from 'constants/appConstants';
import { FormikErrors, FormikValues } from 'formik';
import { useCreateDocument, useGetFiles } from 'hooks/api/utils.hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PresignedUrlData } from 'types/utils/utils.api.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FileUploader from './FileUploader';
import { getFileNameFromFileUrl } from 'utils/helpers/files.helper';

const FileUploaderContainer = (props: Props) => {
    const {
        values,
        setFieldValue,
        validateField,
        setDisableButton,
        accept = 'application/pdf, image/png, image/jpg, image/jpeg',
        errors,
        field = 'filePath',
        isEdit = false,
    } = props;
    const [file, setFile] = React.useState<File | null>(null);
    const [defaultFileUpload, setDefaultFileUpload] = React.useState<
        'firstLoad' | 'second' | 'completed'
    >('firstLoad');
    const [openDocumentReader, setOpenDocumentReader] = React.useState<boolean>(false);
    const [formatError, setFormatError] = React.useState<string | null>(null);
    const [presignedUrls, setPresignedUrls] = React.useState<{
        [key: string]: PresignedUrlData;
    } | null>(null);
    const { t } = useTranslation();
    const { fileUrl, isLoading: isLoadingFetch } = useGetFiles(
        isEdit ? !!values[field] : false,
        values[field],
    );

    const extensionsMapper: { [key: string]: string } = {
        'text/csv': 'csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/pdf': 'pdf',
        'text/plain': 'txt',
        'image/*': 'jpg, png, jpeg',
    };

    const fileExtension = React.useMemo(() => {
        if (file) {
            if (file.type.includes('image')) return file.type.split('/')[0];
            return file.type.split('/')[1];
        } else if (fileUrl) {
            const route = fileUrl?.split('?')[0];
            const key = route.split('/').pop() ?? '';
            const res = key?.split('.').pop() ?? 'default';
            if (extensionsMapper['image/*'].includes(res)) return 'image';
            return res;
        }
        return 'default';
    }, [file, fileUrl]);

    const fileIconMapper = {
        pdf: PdfIcon,
        txt: TXTFileIcon,
        image: PdfIcon,
        default: PdfIcon,
    };

    const validExtensions = React.useMemo(() => {
        let formattedString = '';
        accept?.split(',').forEach((mimeType: string) => {
            if (mimeType.includes('image')) {
                formattedString +=
                    mimeType === 'image/*'
                        ? 'png, jpg, jpeg, svg+xml'
                        : `${mimeType.split('/')[1]}, `;
            } else formattedString += `${extensionsMapper[mimeType]}, `;
        });
        formattedString = formattedString.slice(0, -2);
        return formattedString;
    }, [accept]);

    React.useEffect(() => {
        if (setDisableButton && isEdit && defaultFileUpload !== 'completed') {
            if (defaultFileUpload === 'firstLoad') {
                setDisableButton(true);
                setDefaultFileUpload('second');
            }
            if (!isLoadingFetch && defaultFileUpload === 'second') {
                setDefaultFileUpload('completed');
                setDisableButton(false);
            }
        }
    }, [isLoadingFetch]);

    const config = {
        onSuccess: async (response: any) => {
            const { presignedUrls } = response.data;
            setPresignedUrls(presignedUrls);
        },
    };

    const { mutate: createDocument, isLoading, error: apiError } = useCreateDocument(config);
    const handleFileSelectorChange = async (e: any) => {
        if (accept) {
            const allowedTypes = accept.split(',').map(x => x.trim());
            const fileType = e.target.files[0].type ?? '';
            if (
                !allowedTypes.includes(fileType) &&
                !allowedTypes.includes(fileType.split('/')[0] + '/*')
            ) {
                setFormatError(t('file_uploader_invalid_format', tErrorsContext) + validExtensions);
                return;
            }
        }
        setFormatError('');
        setFile(e.target.files[0]);
        const index = file?.name.indexOf('.');
        setDisableButton && setDisableButton(true);
        createDocument({
            uploadFilesNames: [
                e.target.files[0].name.slice(0, index).replaceAll(' ', '_').toLowerCase(),
            ],
        });
    };

    const cleanerFunction = () => {
        setFile(null);
        setFieldValue(field, undefined);
        setFormatError(null);
    };

    const handleOpenDocumentReader = () => setOpenDocumentReader(true);

    const handleCloseDocumentReader = () => setOpenDocumentReader(false);

    const handleUploadFile = (
        file: File,
        presignedUrls: {
            [key: string]: PresignedUrlData;
        },
    ) => {
        Object.keys(presignedUrls).forEach(async presignedUrl => {
            const { url, fields }: PresignedUrlData = presignedUrls[presignedUrl];
            const formData = new FormData();
            formData.append('key', fields.key);
            formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
            formData.append('x-amz-security-token', fields['x-amz-security-token']);
            formData.append('policy', fields.policy);
            formData.append('signature', fields.signature);
            file && formData.append('file', file);

            await setFieldValue(field, fields.key);
            await axios.post(url, formData);
            if (validateField) validateField(field);
        });
        setDisableButton && setDisableButton(false);
    };

    React.useEffect(() => {
        if (presignedUrls && file) handleUploadFile(file, presignedUrls);
    }, [file, presignedUrls]);

    const childProps = {
        ...props,
        t,
        openDocumentReader,
        handleCloseDocumentReader,
        handleOpenDocumentReader,
        isLoading: isLoading || isLoadingFetch,
        cleanerFunction,
        fileName: file?.name ?? '',
        handleFileSelectorChange,
        error: formatError || (errors[field] as string) || apiError,
        fileIconMapper,
        validExtensions,
        file,
        fileUrl,
        fileExtension,
        canOpenPdfReader: ['pdf'].includes(fileExtension),
        canOpenImgReader: ['image'].includes(fileExtension),
        filename: file?.name ?? getFileNameFromFileUrl(fileUrl ?? ''),
    };

    return <FileUploader {...childProps} />;
};

const propTypes = {
    field: PropTypes.string.isRequired,
    isEdit: PropTypes.bool,
};

interface extraProps {
    values: FormikValues;
    errors: FormikErrors<FormikValues>;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
    ) => void | Promise<void> | Promise<FormikErrors<FormikValues>>;
    displayCleanerFunction?: boolean;
    setDisableButton?: (x: boolean) => void;
    accept?: string;
    label: string;
    validateField?: (field: string) => Promise<void> | Promise<string | undefined>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FileUploaderContainer.propTypes = propTypes;

export default FileUploaderContainer;
