import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BankAccountCard.styled';
import { Grid, Theme, Typography } from '@mui/material';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg?react';
import EditIcon from '../../../../assets/icons/edit-icon.svg?react';
import InfoIcon from '../../../../assets/icons/information-line.svg?react';
import {
    BankAccountStatusOptions,
    BankAccountTypeOptions,
    GetBankAccountsItem,
    SortedBankAccount,
} from 'types/api/cashflow.types';
import { PENDING_DELETE } from 'constants/appConstants';
import BankAccountExtraDataModal from '../BankAccountExtraDataModal';

const BankAccountCard = (props: Props) => {
    const {
        t,
        statusOptions,
        account,
        accountTypeOptions,
        setSelectedAccount,
        setIsEdit,
        setOpenDeleteModal,
        open,
        theme,
        sortedBankAccount,
        openExtraDataModal,
        setOpenExtraDataModal,
        isExteriorOrBroker,
    } = props;

    return (
        <St.CardContainer>
            <Grid container>
                <Grid item xs={account.type === 'BROKER' || account.type === 'EXTERIOR' ? 6 : 12}>
                    {!isExteriorOrBroker && (
                        <Typography variant="h6" color="#307FDD">
                            {t('bank_account_nick_name_title')}: {account.description}
                        </Typography>
                    )}
                    <Typography variant="h6" color="#307FDD">
                        {account.bankName}
                    </Typography>
                    <Typography variant="subtitle1" color={theme.palette.custom.darkblue2}>
                        {account.currency === 'ARS'
                            ? t('bank_account_card_currency_ars_value')
                            : t('bank_account_card_currency_usd_value')}
                    </Typography>
                    <Typography variant="subtitle1" color={theme.palette.custom.darkblue2}>
                        {accountTypeOptions[account.type]}
                    </Typography>
                    {!isExteriorOrBroker && (
                        <Typography variant="subtitle1" color={theme.palette.custom.darkblue2}>
                            {t('bank_account_account_number_title')}: {account.accountNumber}
                        </Typography>
                    )}
                    <Typography
                        variant="subtitle1"
                        color={statusOptions[account.status].color}
                        fontWeight={'bold'}>
                        {statusOptions[account.status].text}{' '}
                        {account.reason ? `${t('bank_account_for_reason')} ${account.reason}` : ''}
                    </Typography>
                </Grid>
            </Grid>
            <St.ContainerButtons>
                {isExteriorOrBroker && (
                    <St.CustomIconButton
                        title={t('bank_account_detail')}
                        onClick={() => {
                            setOpenExtraDataModal(true);
                        }}>
                        <InfoIcon fontSize={'15px'} fill="#307FDD" />
                    </St.CustomIconButton>
                )}

                {account.status !== PENDING_DELETE &&
                    account.type !== 'EXTERIOR' &&
                    account.type !== 'BROKER' && (
                        <>
                            <St.CustomIconButton
                                title={t('bank_account_edit')}
                                onClick={() => {
                                    setIsEdit(true);
                                    setSelectedAccount(account);
                                    open();
                                }}>
                                <EditIcon fontSize={'15px'} color="#307FDD" />
                            </St.CustomIconButton>

                            <St.CustomIconButton
                                title={t('bank_account_delete_button')}
                                onClick={() => {
                                    setSelectedAccount(account);
                                    setOpenDeleteModal(true);
                                }}>
                                <DeleteIcon fontSize={'15px'} color="#307FDD" />
                            </St.CustomIconButton>
                        </>
                    )}
            </St.ContainerButtons>
            {sortedBankAccount && (
                <BankAccountExtraDataModal
                    open={openExtraDataModal}
                    accountType={account.type}
                    sortedBankAccount={sortedBankAccount}
                    close={() => setOpenExtraDataModal(false)}
                />
            )}
        </St.CardContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    openExtraDataModal: PropTypes.bool.isRequired,
    setOpenExtraDataModal: PropTypes.func.isRequired,
    isExteriorOrBroker: PropTypes.bool.isRequired,
};

interface extraProps {
    statusOptions: BankAccountStatusOptions;
    accountTypeOptions: BankAccountTypeOptions;
    account: GetBankAccountsItem;
    setSelectedAccount: React.Dispatch<React.SetStateAction<GetBankAccountsItem | null>>;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    open(): void;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: Theme;
    sortedBankAccount?: SortedBankAccount;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountCard.propTypes = propTypes;

export default BankAccountCard;
