import { Box, IconButton, styled, Typography } from '@mui/material';
import QuestionMarkRadiusIcon from 'assets/svg/questionMarkRadius.svg?react';

export default {
    Container: styled(Box)(() => ({})) as typeof Box,
    ExchangeQuotationsContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
    })) as typeof Box,
    Caption: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
        fontStyle: 'italic',
        fontWeight: '500',
    })) as typeof Typography,
    AvailableText: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
        fontWeight: 500,
    })) as typeof Typography,
    ButtonsContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1rem',
    })) as typeof Box,
    AvailableContainer: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    })) as typeof Box,
    IsManualContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: '15px',
        paddingLeft: '1rem',
    })),
    FlexContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
    })),
    IconButton: styled(IconButton)(() => ({
        padding: '4px',
    })),
    QuestionMarkRadiusIcon: styled(QuestionMarkRadiusIcon)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
        height: '15px',
    })),
};
