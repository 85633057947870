import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ProfileChangeUsernamePage.styled';
import { FormikContextType, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import CustomInput from 'components/common/CustomInput';
import ErrorText from 'components/common/ErrorText';

const ProfileChangeUsernamePage = ({
    t,
    disableForm,
    formik,
    isLoading,
    errorMessage,
    SnackBar,
    handleGoHome,
    handleChange,
}: Props) => {
    const { errors, values, setFieldValue } = formik;
    return (
        <St.PageWrapper>
            <FormikProvider value={formik} data-testid="changeUsername-form">
                <St.Form>
                    <St.Typography fontSize={24} my={1.5}>
                        {t('change_username_title')}
                    </St.Typography>
                    <St.FieldWrapper>
                        <CustomInput
                            field="oldUsername"
                            errors={errors || errorMessage}
                            label={t('old_username')}
                            placeholder={t('old_username')}
                            setFieldValue={setFieldValue}
                            values={values}
                            autoFocus
                            regex={/^\S*$/}
                        />
                    </St.FieldWrapper>
                    <St.FieldWrapper>
                        <CustomInput
                            field="username"
                            errors={errors || errorMessage}
                            label={t('new_username')}
                            placeholder={t('username')}
                            handleChange={handleChange}
                            values={values}
                            autoFocus
                            helperText={!errorMessage && t('new_username_requirements')}
                            regex={/^\S*$/}
                        />
                    </St.FieldWrapper>
                    {errorMessage && <ErrorText text={errorMessage} />}
                    <St.ButtonWrapper>
                        <ActionButton
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={isLoading}
                            onClick={handleGoHome}>
                            {t('back', tCommon)}
                        </ActionButton>
                        <ActionButton
                            disabled={disableForm}
                            fullWidth
                            type="submit"
                            variant="contained"
                            loading={isLoading}>
                            {t('save', tCommon)}
                        </ActionButton>
                    </St.ButtonWrapper>
                    <SnackBar />
                </St.Form>
            </FormikProvider>
        </St.PageWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    handleChange: PropTypes.func,
};

interface extraProps {
    disableForm: boolean;
    formik: FormikContextType<any>;
    isLoading: boolean;
    SnackBar: any;
    handleSubmit(values: any): void;
    handleGoHome(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileChangeUsernamePage.propTypes = propTypes;

export default ProfileChangeUsernamePage;
