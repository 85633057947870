import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SearchClientModal from './SearchClientModal';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { MarketAccount } from 'types/api/marketAccount.types';
import { useGetChannelMarketAccountsForModal } from 'hooks/api/marketAccount.hooks';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { debounce } from 'utils/helpers/commonHelper';

const SearchClientModalContainer = (props: Props) => {
    const { isOpen, close } = props;
    const { t } = useTranslation();
    const [selectedAccount, setSelectedAccount] = React.useState<MarketAccount | null>(null);

    const { setSelectedCustomer } = React.useContext(AuthContext);

    const onChange = (value: MarketAccount) => setSelectedCustomer(value);

    const [filterQueryString, setFilterQueryString] = React.useState<string | null>(null);

    const { data, isLoading, errorMessage } = useGetChannelMarketAccountsForModal({
        filterQueryString,
        forceDisable: filterQueryString === null,
    } as GridHookParams);

    const handleConfirm = () => {
        if (selectedAccount) {
            setSelectedCustomer(selectedAccount);
        }
        close();
    };

    const handleSearch = (query: string) => {
        if (query === '') {
            setFilterQueryString(null);
            return;
        }
        if (query.length > 2) {
            setFilterQueryString(`&page=1&pageSize=16&sortField=name&sort=ASC&q=${query}`);
        }
    };

    const debouncedSearch = debounce((value: string) => {
        handleSearch(value);
    }, 1000);

    const childProps = {
        ...props,
        t,
        close,
        isOpen,
        onChange,
        debouncedSearch,
        handleConfirm,
        selectedAccount,
        setSelectedAccount,
        setFilterQueryString,
        marketAccounts:
            data?.items.map(
                m =>
                    ({
                        ...m,
                        id: m.customerCode,
                    }) as MarketAccount,
            ) ?? [],
        errorMarketAccounts: errorMessage,
        isLoadingMarketAccounts: isLoading && filterQueryString !== null,
    };

    return <SearchClientModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isOpen: boolean;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SearchClientModalContainer.propTypes = propTypes;

export default SearchClientModalContainer;
