import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommercialItemButton from './CommercialItemButton';
import { CMSCategoryItem, CMSRedirects } from 'types/api/cms.types';
import { download } from 'utils/helpers/files.helper';
import { useNavigate } from 'react-router-dom';
import { V_CMS_URL } from 'constants/envVarConstants';
import { VALID_VIDEO_EXTENSIONS } from 'constants/appConstants';

const CommercialItemButtonContainer = (props: Props) => {
    const { id, redirects, subcategories, name } = props;
    const [videoUrl, setVideoUrl] = React.useState<string | null>(null);
    const [fileUrl, setFileUrl] = React.useState<string | null>(null);
    const navigate = useNavigate();

    const handleManageRedirect = (redirect: CMSRedirects) => {
        if (redirect.file) {
            if (!VALID_VIDEO_EXTENSIONS.includes(redirect.file.ext))
                return download(redirect.file?.url);
            setVideoUrl(redirect.file?.url);
            return;
        }
        if (redirect.url) window.open(redirect.url, '_blank');
    };

    const handleSubmit = React.useCallback(() => {
        if (subcategories?.length) {
            navigate(`/commercial/${id}`);
            return;
        }
        const filterRedirects = redirects?.filter(r => r.category.name === name);
        if (!filterRedirects) return;
        if (filterRedirects.length > 1) {
            const videoRedirect = filterRedirects.find(r => {
                if (!r.file) return false;
                return VALID_VIDEO_EXTENSIONS.includes(r.file.ext);
            });
            const downloadRedirect = filterRedirects.find(r => {
                if (!r.file) return false;
                return !VALID_VIDEO_EXTENSIONS.includes(r.file.ext);
            });
            if (videoRedirect) setVideoUrl(videoRedirect.file?.url ?? null);
            if (downloadRedirect) setFileUrl(downloadRedirect.file?.url ?? null);
            return;
        }
        const redirect = filterRedirects[0];
        handleManageRedirect(redirect);
    }, [redirects, name, subcategories, id, navigate]);

    const childProps = {
        ...props,
        handleSubmit,
        videoUrl,
        setVideoUrl,
        fileUrl,
    };

    return <CommercialItemButton {...childProps} />;
};

const propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};

interface extraProps {
    redirects?: CMSRedirects[];
    subcategories: CMSCategoryItem[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialItemButtonContainer.propTypes = propTypes;

export default CommercialItemButtonContainer;
