import BlogPage from 'components/pages/BlogPage';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import i18n from 'utils/i18n';
import { mainRoutesPaths, onboardingRoutesPaths } from '../routesPaths';
import OnboardingHomePage from 'components/pages/OnboardingHomePage';
import OnboardingComplementaryDataPage from 'components/pages/OnboardingComplementaryDataPage';
import OnboardingValidatePersonPage from 'components/pages/OnboardingValidatePersonPage';
import OnboardingInvestmentTestPage from 'components/pages/OnboardingInvestmentTestPage';
import OnboardingContactInfoPage from 'components/pages/OnboardingContactInfoPage';
import OnboardingDniPhotoPage from 'components/pages/OnboardingDniPhotoPage';
import OnboardingPersonalDataPage from 'components/pages/OnboardingPersonalDataPage';
import OnboardingStepIncomeProofPage from 'components/pages/OnboardingStepIncomeProofPage';
import OnboardingContractPage from 'components/pages/OnboardingContractPage';
import OnboardingInvestmentTestResultPage from 'components/pages/OnboardingInvestmentTestResultPage';
import ProfileChangePasswordPage from 'components/pages/ProfileChangePasswordPage';
import ProfileDeleteAccountPage from 'components/pages/ProfileDeleteAccountPage';
import ProfileChangeUsernamePage from 'components/pages/ProfileChangeUsernamePage';

export const onboardingRoutes: Array<SubRouteInfo> = [
    {
        name: 'onboarding',
        path: onboardingRoutesPaths.home,
        title: i18n.t('layout_main_navigation_onboarding_home') as string,
        component: OnboardingHomePage,
    },
    {
        name: 'blog',
        path: onboardingRoutesPaths.blog,
        title: i18n.t('layout_main_navigation_blog') as string,
        component: BlogPage,
    },
    {
        name: 'validate_person',
        path: onboardingRoutesPaths.validatePerson,
        component: OnboardingValidatePersonPage,
    },
    {
        name: 'personal_data',
        path: onboardingRoutesPaths.personalData,
        component: OnboardingPersonalDataPage,
    },
    {
        name: 'contact_info',
        path: onboardingRoutesPaths.contactInfo,
        component: OnboardingContactInfoPage,
    },
    {
        name: 'complementary_data',
        path: onboardingRoutesPaths.complementarydata,
        component: OnboardingComplementaryDataPage,
    },
    {
        name: 'upload_document',
        path: onboardingRoutesPaths.uploadDocument,
        component: OnboardingDniPhotoPage,
    },
    {
        name: 'investment_test',
        path: onboardingRoutesPaths.investmentTestQuestions,
        component: OnboardingInvestmentTestPage,
    },
    {
        name: 'investment_test_result',
        path: onboardingRoutesPaths.investmentTestResult,
        component: OnboardingInvestmentTestResultPage,
    },
    {
        name: 'proof_of_income',
        path: onboardingRoutesPaths.proofOfIncome,
        component: OnboardingStepIncomeProofPage,
    },
    {
        name: 'contract',
        path: onboardingRoutesPaths.contract,
        component: OnboardingContractPage,
    },
    {
        name: 'profile',
        path: mainRoutesPaths.profile,
        title: i18n.t('layout_main_navigation_profile') as string,
        subRoutes: [
            {
                name: 'deleteAccount',
                path: mainRoutesPaths.deleteAccount,
                title: i18n.t('layout_main_navigation_delete_account') as string,
                component: ProfileDeleteAccountPage,
            },
            {
                name: 'replaceUsername',
                path: mainRoutesPaths.replaceUsername,
                title: i18n.t('layout_main_navigation_replace_user_name') as string,
                component: ProfileChangeUsernamePage,
            },
            {
                name: 'replacePassword',
                path: mainRoutesPaths.replacePassword,
                title: i18n.t('layout_main_navigation_replace_password') as string,
                component: ProfileChangePasswordPage,
            },
        ],
    },
];

export const extraOnboardingRoutes: Array<SubRouteInfo> = [
    {
        name: 'onboarding_base_route',
        path: '/',
        title: i18n.t('layout_main_navigation_onboarding_home') as string,
        component: OnboardingHomePage,
    },
];
