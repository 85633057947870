import React, { useEffect, useRef, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import RequestAutocomplete from './RequestAutocomplete';
import { useTranslation } from 'react-i18next';
import { CommonFilter } from 'types/common/tableLayout.types';
import { useGetAutocompleteInfo } from 'hooks/api/utils.hooks';

const RequestAutocompleteContainer = (props: Props) => {
    const { t } = useTranslation();
    const { requestAutocomplete, filterstring, setfilterstring, query } = props;
    const [options, setOptions] = useState([] as any[]);
    const [selectedValues, setSelectedValues] = useState<any | any[]>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const autocompleteRef = useRef<HTMLDivElement | null>(null);
    const { mutate, isLoading, isError } = useGetAutocompleteInfo();

    const handleInputChange = (_: any, value: any) => {
        setInputValue(value);
        if (value === '') {
            setOptions([]);
            setOpenPopover(false);
            return;
        }
        setOpenPopover(true);
        mutate(
            {
                url: requestAutocomplete?.requestUrl || '',
                param: `${requestAutocomplete?.param}=${value}${requestAutocomplete?.extraFilter || ''}`,
            },
            {
                onSuccess: (res: any) => setOptions(res.data.items),
            },
        );
    };

    const getNestedValue = (obj: any, path: string) => {
        if (path.includes('[') && path.includes(']')) {
            return path.split('.').reduce((acc, part) => {
                const arrayMatch = part.match(/(\w+)\[(\d+)\]/);
                if (arrayMatch) {
                    const [, arrayKey, index] = arrayMatch;
                    return acc[arrayKey][index];
                }
                return acc[part];
            }, obj);
        } else {
            return obj[path];
        }
    };

    const handleChange = (selectedOptions: any | any[]) => {
        setSelectedValues(selectedOptions);
        setInputValue(
            requestAutocomplete?.optionLabel
                ? requestAutocomplete?.optionLabel(selectedOptions)
                : selectedOptions.name,
        );
        setOpenPopover(false);
        if (!selectedOptions) {
            return setfilterstring((prev: string) =>
                prev.replace(new RegExp(`&${query}=[^&]*`, 'g'), ''),
            );
        }

        const selectedValues = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
        // console.log(selectedValues);
        const updatedQueryString = selectedValues
            .map(
                option =>
                    `&${query}=${requestAutocomplete?.keyTofilter ? getNestedValue(option, requestAutocomplete?.keyTofilter) : option.name}`,
            )
            .join('');
        setfilterstring(
            (prev: string) =>
                prev.replace(new RegExp(`&${query}=[^&]*`, 'g'), '') + updatedQueryString,
        );
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                autocompleteRef.current &&
                !autocompleteRef.current.contains(event.target as Node)
            ) {
                setOpenPopover(false); // Cerrar el popover
            }
        };

        // Agregar el event listener al montar el componente
        document.addEventListener('mousedown', handleClickOutside);

        // Limpiar el event listener al desmontar el componente
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const childProps = {
        ...props,
        options,
        optionLabel: requestAutocomplete?.optionLabel,
        isLoading,
        isError,
        selectedValues,
        handleInputChange,
        handleChange,
        t,
        inputValue,
        openPopover,
        setOpenPopover,
        autocompleteRef,
    };

    useEffect(() => {
        if (!filterstring) setSelectedValues(null);
    }, [filterstring]);

    return <RequestAutocomplete {...childProps} />;
};

const propTypes = {};

interface extraProps extends CommonFilter {
    filterstring: string;
    setfilterstring: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RequestAutocompleteContainer.propTypes = propTypes;

export default RequestAutocompleteContainer;
