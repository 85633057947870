import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import USDFieldsContent from './USDFieldsContent';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types/common/general.types';
import { useGetInstrumentDetail } from 'hooks/ws/prices.hooks';
import { InstrumentType } from 'types/api/instruments.types';

const USDFieldsContentContainer = (props: Props) => {
    const { currencyType, ticker, available, instrumentType } = props;
    const { t } = useTranslation('home');
    const [showUsdFields, setshowUsdFields] = React.useState(false);
    const { tickerPriceInfo, isLoading } = useGetInstrumentDetail('24hs', ticker);

    const valuation = React.useMemo(() => {
        if (!tickerPriceInfo) return 0;
        if (instrumentType === 'BOND') return (tickerPriceInfo?.price * available) / 100;
        return tickerPriceInfo?.price * available;
    }, [tickerPriceInfo, available, instrumentType]);

    const childProps = {
        ...props,
        t,
        isLoading,
        showUsdFields,
        setshowUsdFields,
        valuation,
        price: tickerPriceInfo?.price,
        showText:
            currencyType === 'USD'
                ? t('instrument_show_valuation_usd').toUpperCase()
                : t('instrument_show_valuation_usd_cable').toUpperCase(),
        hideText:
            currencyType === 'USD'
                ? t('instrument_no_show_valuation_usd').toUpperCase()
                : t('instrument_no_show_valuation_usd_cable').toUpperCase(),
    };

    return <USDFieldsContent {...childProps} />;
};

const propTypes = {
    ticker: PropTypes.string.isRequired,
};

interface extraProps {
    available: number;
    currencyType: Currency;
    instrumentType: InstrumentType;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
USDFieldsContentContainer.propTypes = propTypes;

export default USDFieldsContentContainer;
