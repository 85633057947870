import * as React from 'react';
import * as Yup from 'yup';
import i18n from 'utils/i18n';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfileChangeUsernamePage from './ProfileChangeUsernamePage';
import { useTranslation } from 'react-i18next';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { FormikValues, useFormik } from 'formik';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useNavigate } from 'react-router-dom';
import { useRouteToHome } from 'utils/helpers/routesHelper';
import { useChangeUsername } from 'hooks/api/auth.hooks';
import { AuthContext } from 'context/auth.context';

const getInitialValues = () => ({
    oldUsername: '',
    username: '',
});

const getValidationSchema = (currentUsername: string) =>
    Yup.lazy(() =>
        Yup.object().shape({
            oldUsername: Yup.string()
                .required(tRequiredFieldError)
                .oneOf(
                    [currentUsername],
                    String(i18n.t('old_username_must_match_current', tErrorsContext)),
                ),
            username: Yup.string()
                .min(8, String(i18n.t('username_field_min_length_validation', tErrorsContext)))
                .required(tRequiredFieldError)
                .notOneOf(
                    [currentUsername],
                    String(i18n.t('username_must_be_different', tErrorsContext)),
                )
                .matches(
                    /^[a-zA-Z0-9]+$/,
                    String(i18n.t('username_must_be_alphanumeric', tErrorsContext)),
                )
                .trim(),
        }),
    );

const ProfileChangeUsernamePageContainer = (props: Props) => {
    const { t } = useTranslation('auth');

    const { userData } = React.useContext(AuthContext);
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    const navigate = useNavigate();
    const homeUrl = useRouteToHome();
    const handleGoHome = () => navigate(homeUrl);

    const { mutate, isLoading, errorMessage, setErrorMessage } = useChangeUsername(
        setSnackBarMessage,
        handleGoHome,
    );

    const handleSubmit = React.useCallback(
        (values: FormikValues) => {
            const { username } = values;
            mutate({ body: { username } });
        },
        [mutate],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(userData?.username ?? ''),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit, userData?.username],
    );

    const formik = useFormik(formikInitProps);
    const disableForm = React.useMemo(
        () =>
            !formik.values.username ||
            !formik.values.oldUsername ||
            Object.keys(formik.errors).length != 0,
        [formik.errors, formik.values.username, formik.values.oldUsername],
    );

    const handleChange = (v: string) => {
        formik.setFieldValue('username', v, true);
        setErrorMessage(null);
    };

    const childProps = {
        ...props,
        t,
        formik,
        isLoading,
        disableForm,
        errorMessage,
        SnackBar,
        handleSubmit,
        handleGoHome,
        handleChange,
    };

    return <ProfileChangeUsernamePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileChangeUsernamePageContainer.propTypes = propTypes;

export default ProfileChangeUsernamePageContainer;
