import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import WebSocket from 'isomorphic-ws';
import { V_BO_WS_PRICES_URL } from 'constants/envVarConstants';
import { WSTickerPrices } from 'types/context/prices.context.types';

export const PricesContext = createContext(
    {} as {
        subscribeToPricesWS(tickers: string[]): void;
        subscribe24hs(tickers: string[]): void;
        subscribeCI(tickers: string[]): void;
        unsubscribeToPricesWS(tickers: string[]): void;
        unsubscribe24hs(tickers: string[]): void;
        unsubscribeCI(tickers: string[]): void;
        tickerPricesCI: WSTickerPrices[] | null;
        tickerPrices24hs: WSTickerPrices[] | null;
        isConnectedCI: boolean;
        isConnected24hs: boolean;
    },
);

const PricesProvider = ({ children }: any) => {
    const [connectionCI, setConnectionCI] = useState<WebSocket | null>(null);
    const [connection24hs, setConnection24hs] = useState<WebSocket | null>(null);
    const [tickerPricesCI, setTickerPricesCI] = useState<WSTickerPrices[]>([]);
    const [tickerPrices24hs, setTickerPrices24hs] = useState<WSTickerPrices[]>([]);
    const [isConnectedCI, setIsConnectedCI] = useState<boolean>(false);
    const [isConnected24hs, setIsConnected24hs] = useState<boolean>(false);

    const connectWS = (
        setConnection: (conn: WebSocket | null) => void,
        setIsConnected: (isConnected: boolean) => void,
        setTickerPrices: Dispatch<SetStateAction<WSTickerPrices[]>>,
        label: 'CI' | '24hs',
    ) => {
        const ws = new WebSocket(V_BO_WS_PRICES_URL);

        ws.onopen = () => {
            // console.log(`Connected to WebSocket for ${label}`);
            setIsConnected(true);
        };

        ws.onmessage = (ev: any) => {
            // console.log(`${label} data:`, ev.data);

            if (
                ev.data.includes('No data found yet for instrument') ||
                ev.data.includes('Clean Subscription') ||
                ev.data.includes('Unsubscribed from tickers')
            )
                return;

            const data = JSON.parse(ev.data);

            setTickerPrices(prevTickerPrices => {
                const newTickerPrices = [...prevTickerPrices];
                const index = newTickerPrices.findIndex(
                    tickerPrice => tickerPrice.ticker === data.ticker,
                );

                index !== -1 ? (newTickerPrices[index] = data) : newTickerPrices.push(data);

                return newTickerPrices;
            });
        };

        ws.onerror = (error: any) => console.error(`WebSocket error on ${label}:`, error);

        ws.onclose = () => {
            // console.log(`WebSocket connection for ${label} closed`);
            setIsConnected(false);
            setConnection(null);
        };

        setConnection(ws);
    };

    const disconnectWS = (connection: WebSocket | null, label: string) => {
        if (connection) {
            // console.log(`Disconnecting WebSocket for ${label}`);
            connection.close();
        }
    };

    const sendMessage = (ws: WebSocket | null, message: any) => {
        if (!(ws && ws.readyState === WebSocket.OPEN))
            return console.warn('WebSocket is not open. Cannot send message:', message);

        ws.send(JSON.stringify(message));
    };

    const manageSubscription = (
        connection: WebSocket | null,
        tickers: string[],
        term: number,
        action: 'SUBSCRIBE' | 'UNSUBSCRIBE',
    ) => {
        const message = {
            action,
            tickers,
            term,
            cleanSubscription: action === 'SUBSCRIBE',
        };

        // console.log(`${action} to ${term === 0 ? 'CI' : '24hs'}`, tickers);

        setTimeout(() => {
            sendMessage(connection, message);
        }, 400);
    };

    const connectCI = () => connectWS(setConnectionCI, setIsConnectedCI, setTickerPricesCI, 'CI');
    const connect24hs = () =>
        connectWS(setConnection24hs, setIsConnected24hs, setTickerPrices24hs, '24hs');

    const disconnectCI = () => disconnectWS(connectionCI, 'CI');
    const disconnect24hs = () => disconnectWS(connection24hs, '24hs');

    const subscribeCI = (tickers: string[]) =>
        manageSubscription(connectionCI, tickers, 0, 'SUBSCRIBE');

    const subscribe24hs = (tickers: string[]) =>
        manageSubscription(connection24hs, tickers, 1, 'SUBSCRIBE');

    const subscribeToPricesWS = (tickers: string[]) => {
        subscribeCI(tickers);
        subscribe24hs(tickers);
    };

    const unsubscribeCI = (tickers: string[]) => {
        manageSubscription(connectionCI, tickers, 0, 'UNSUBSCRIBE');
        const finalTickers = tickerPricesCI.filter(t => !tickers.includes(t.ticker));
        setTickerPrices24hs(finalTickers);
    };

    const unsubscribe24hs = (tickers: string[]) => {
        manageSubscription(connection24hs, tickers, 1, 'UNSUBSCRIBE');
        const finalTickers = tickerPrices24hs.filter(t => !tickers.includes(t.ticker));
        setTickerPrices24hs(finalTickers);
    };

    const unsubscribeToPricesWS = (tickers: string[]) => {
        unsubscribeCI(tickers);
        unsubscribe24hs(tickers);
    };

    useEffect(() => {
        connectCI();
        connect24hs();
        return () => {
            disconnectCI();
            disconnect24hs();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PricesContext.Provider
            value={{
                subscribeToPricesWS,
                subscribe24hs,
                subscribeCI,
                unsubscribeToPricesWS,
                unsubscribe24hs,
                unsubscribeCI,
                tickerPricesCI,
                tickerPrices24hs,
                isConnectedCI,
                isConnected24hs,
            }}>
            {children}
        </PricesContext.Provider>
    );
};

export default PricesProvider;
