import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './USDFieldsContent.styled';
import { Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { convertCurrencyFormat } from 'utils/helpers/commonHelper';
import CircularSpinner from 'components/common/CircularSpinner';

const USDFieldsContent = (props: Props) => {
    const {
        t,
        ticker,
        price,
        hideText,
        showText,
        valuation,
        isLoading,
        showUsdFields,
        setshowUsdFields,
    } = props;
    if (isLoading) return <CircularSpinner />;
    if (!price) return <></>;
    return (
        <St.Container>
            <St.ButtonContainer>
                <ActionButton
                    variant="contained"
                    sx={{ width: ' fit-content', fontSize: '0.90rem' }}
                    onClick={() => setshowUsdFields(!showUsdFields)}>
                    {showUsdFields ? hideText : showText}
                </ActionButton>
            </St.ButtonContainer>
            {showUsdFields && (
                <St.ContainerTextUSD mt={2}>
                    <St.BlueText fontSize={'1.5rem'}>{ticker} 24hs</St.BlueText>
                    <Typography fontSize="1rem">
                        {t('instrument_price')}: {convertCurrencyFormat(price, 'USD')}
                    </Typography>
                    <Typography fontSize="1rem">
                        {t('instrument_valuation')}: {convertCurrencyFormat(valuation, 'USD')}
                    </Typography>
                </St.ContainerTextUSD>
            )}
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    ticker: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showText: PropTypes.string.isRequired,
    hideText: PropTypes.string.isRequired,
    showUsdFields: PropTypes.bool.isRequired,
    setshowUsdFields: PropTypes.func.isRequired,
};

interface extraProps {
    valuation: number;
    price?: number;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
USDFieldsContent.propTypes = propTypes;

export default USDFieldsContent;
