import { withFakeData } from 'api/fakedata';
import { axiosAnonInstance, axiosDataInstance } from 'api/axiosInstances';
import {
    ForgotPasswordConfirmationRequest,
    ForgotPasswordRequest,
    GetProfileResponse,
    LoginRequest,
    LoginResponse,
    LogoutRequest,
    LogoutResponse,
    RemoveAccountResponse,
    RecommendedWalletResponse,
    ValidateCodeRequest,
    ValidateCodeResponse,
    PreviewTerms,
    GetSignedContractResponse,
    GetPreferencesResponse,
    PreferencesBody,
} from 'types/api/auth.types';

const apiName = 'auth';
const AUTH_BASE_URL = '/auth/v1_0';

export default {
    logout: (body: LogoutRequest) =>
        withFakeData<LogoutResponse>(
            () => axiosDataInstance.post('/auth/logout', body),
            apiName,
            'logout',
            true,
            500,
        ),
    login: (argument: LoginRequest) =>
        withFakeData<LoginResponse>(
            () => axiosAnonInstance.post(`${AUTH_BASE_URL}/login`, argument),
            apiName,
            'login',
            false,
            500,
        ),
    register: (email: string) =>
        withFakeData(
            () => axiosAnonInstance.post(`${AUTH_BASE_URL}/register`, { email }),
            apiName,
            'register',
            false,
            500,
        ),
    resendCode: (email: string) =>
        withFakeData(
            () => axiosAnonInstance.post(`${AUTH_BASE_URL}/resend_code`, { email }),
            apiName,
            'register',
            false,
            500,
        ),
    validateCode: (argument: ValidateCodeRequest) =>
        withFakeData<ValidateCodeResponse>(
            () => axiosAnonInstance.post(`${AUTH_BASE_URL}/validate_code`, argument.body),
            apiName,
            'sign-up',
            false,
            500,
        ),
    getProfileInfo: () =>
        withFakeData<GetProfileResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/me`),
            apiName,
            'login',
            false,
            500,
        ),
    forgotPassword: (req: ForgotPasswordRequest) =>
        withFakeData(
            () => axiosAnonInstance.post(`${AUTH_BASE_URL}/password-recovery`, req),
            apiName,
            'password-recovery',
            false,
            500,
        ),
    forgotPasswordConfirmation: (req: ForgotPasswordConfirmationRequest) =>
        withFakeData(
            () => axiosAnonInstance.post('/forgot-password/confirmation', req),
            apiName,
            'forgot-password-confirmation',
            true,
            500,
        ),
    getRecommendedWallet: () =>
        withFakeData<RecommendedWalletResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/recommended_wallet/`),
            apiName,
            'recommended-wallet',
        ),
    getPreferences: () =>
        withFakeData<GetPreferencesResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/preferences`),
            apiName,
            'get-preferences',
            false,
            500,
        ),
    handlePreferences: (body: PreferencesBody) =>
        withFakeData(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/preferences`, body),
            apiName,
            'handle-preferences',
            false,
            500,
        ),
    sendEmailEnableMfa: (body: any) =>
        withFakeData(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/mfa/enable_email`),
            apiName,
            'enable-mfa',
            false,
            500,
        ),
    validateMfa: (body: any) =>
        withFakeData(
            () => {
                axiosDataInstance.post(`${AUTH_BASE_URL}/mfa/validate`, body);
            },
            apiName,
            'mfa-otp',
            false,
            500,
        ),
    sendEmailRequestOtp: (req: any) =>
        withFakeData(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/mfa/request_otp`),
            apiName,
            'request_otp',
            false,
            500,
        ),
    disableMfa: (body: any) =>
        withFakeData(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/mfa/disable`, body),
            apiName,
            'request_otp',
            false,
            500,
        ),
    getPendingRemoveAccount: () =>
        withFakeData<RemoveAccountResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/remove-account`),
            apiName,
            'pending-remove-account',
            false,
            500,
        ),
    sendRemoveAccountRequest: (body: any) =>
        withFakeData(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/remove-account`, body),
            apiName,
            'remove-account-request',
            false,
            500,
        ),
    setFavoriteMarketAccount: (body: { customerCodes: string[] }) =>
        withFakeData(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/market_account/favorites`, body),
            apiName,
            'favorite-market-account',
            false,
            500,
        ),
    deleteFavoriteMarketAccount: (body: { customerCodes: string[] }) =>
        withFakeData(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/market_account/favorites/delete`, body),
            apiName,
            'delete-favorite-market-account',
            false,
            500,
        ),
    acceptTermsConditions: () =>
        withFakeData(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/accept_terms`),
            apiName,
            'accept-terms-conditions',
            false,
            500,
        ),
    previewTermsConditions: () =>
        withFakeData<PreviewTerms>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/preview_terms`),
            apiName,
            'preview-terms-conditions',
            false,
            500,
        ),
    changeUsername: ({ body }: { body: { username: string } }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${AUTH_BASE_URL}/username`, body),
            apiName,
            'change-username',
            false,
            500,
        ),
    changePassword: ({ body }: { body: { oldPassword: string; newPassword: string } }) =>
        withFakeData(
            () => axiosDataInstance.post(`${AUTH_BASE_URL}/password`, body),
            apiName,
            'change-password',
            false,
            500,
        ),
    getSignedContract: () =>
        withFakeData<GetSignedContractResponse>(
            () => axiosDataInstance.get(`${AUTH_BASE_URL}/signed_contract`),
            apiName,
            'signed-contract',
            false,
            500,
        ),
};
