import * as React from 'react';
import St from './WalletInstrument.styled';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentModal from './InstrumentModal';
import {
    CurrencyFormatted,
    InstrumentFormatted,
    InstrumentFormattedFormModal,
    ProcessedData,
} from 'types/api/instruments.types';
import CustomIconCreator from 'components/common/CustomIconCreator';
import DropDownIcon from 'assets/icons/arrow-drop-down-line.svg?react';
import InstrumentTypeTable from './InstrumentTypeTable';
import InstrumentCurrenciesTable from './InstrumentCurrenciesTable';
import CustomLoader from 'components/common/CustomLoader';

const WalletInstrument = (props: Props) => {
    const {
        instrumentsAmount,
        t,
        openInstrumentModal,
        setOpenInstrumentModal,
        handleInstrumentClick,
        processedData,
        isLoading,
        selectedInstrument,
    } = props;

    return (
        <St.WalletInstrumentWrapper>
            <St.WalletInstrumentContainer>
                <St.Subtitle variant="subtitle1" color="black !important">
                    {t('wallet_instruments', { instrumentsAmount }) as string}
                </St.Subtitle>
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    <>
                        {processedData.map((instrument, index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    sx={{
                                        '.MuiAccordionSummary-content': {
                                            alignItems: 'center',
                                        },
                                    }}
                                    expandIcon={
                                        <CustomIconCreator icon={DropDownIcon} alt="dropdown" />
                                    }>
                                    <Typography mr={1}>
                                        {t(instrument.type, { ns: 'home' })} (
                                        {t(instrument.quantity)})
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {instrument.type === 'CURRENCY' ? (
                                        <InstrumentCurrenciesTable
                                            handleInstrumentClick={handleInstrumentClick}
                                            currencies={instrument.info as CurrencyFormatted[]}
                                        />
                                    ) : (
                                        <InstrumentTypeTable
                                            instrumentType={instrument.type}
                                            instruments={instrument.info as InstrumentFormatted[]}
                                            handleInstrumentClick={handleInstrumentClick}
                                        />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </>
                )}
                {selectedInstrument && (
                    <InstrumentModal
                        t={t}
                        openModal={openInstrumentModal}
                        setOpenModal={setOpenInstrumentModal}
                        selectedInstrument={selectedInstrument}
                    />
                )}
            </St.WalletInstrumentContainer>
        </St.WalletInstrumentWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    instrumentsAmount: number;
    openInstrumentModal: boolean;
    setOpenInstrumentModal: (x: boolean) => void;
    handleInstrumentClick: (instrument: any) => void;
    selectedInstrument: InstrumentFormattedFormModal | null;
    selectedInstrumentType: string;
    processedData: ProcessedData[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletInstrument.propTypes = propTypes;

export default WalletInstrument;
