import { Box, Typography, styled } from '@mui/material';
import { Form } from 'formik';

export default {
    PageWrapper: styled(Box)(() => ({})),
    Form: styled(Form)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            m: { xs: '0 auto 4rem', sm: '0rem 4rem 4rem' },
            gap: 2,
        }),
    ),
    Typography: styled(Typography)(({ theme }) => ({ color: theme.palette.primary.main })),
    FieldWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { xs: '100%', sm: '500px' },
        }),
    ),
    ButtonWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '1rem',
            button: { maxWidth: { xs: '100%', sm: 'fit-content' }, margin: 0 },
        }),
    ),
};
