import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import WithdrawalModal from './WithdrawalModal';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { GetBankAccountsResponse } from 'types/api/cashflow.types';
import { useGetBankAccounts, useWithdrawal } from 'hooks/api/cahsflow.hooks';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { AuthContext } from 'context/auth.context';
import i18n from 'utils/i18n';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { MarketContext } from 'context/market.context';
import { LockedFunctionalityNames } from 'types/api/rules.types';
import { isMarketClosed } from 'utils/helpers/dateHelper';

const normalizeCurrency = (currency: string) => {
    if (currency === 'USD Cable') {
        return 'USDC';
    }
    return currency.replace(/\s+/g, '_').toUpperCase();
};

const normalizeCashflowCurrency = (currency: string) => {
    if (currency === 'USD Cable') {
        return 'USD-C';
    }
    return currency.trim().replace(/\s+/g, '-').toUpperCase();
};

const getInitialValues = () => ({
    destinationAccount: '',
    currency: '',
    options: [],
    comment: '',
    amountToExtract: 0,
});

const getValidationSchema = (
    arsAvailable: number | undefined,
    usdAvailable: number | undefined,
    usdCableAvailable: number | undefined,
    usdmTAvailable: number | undefined,
    usdcExtAvailable: number | undefined,
    usdcTAvailable: number | undefined,
    bankAccounts: GetBankAccountsResponse | undefined,
    t: (key: string) => string,
) =>
    Yup.object().shape({
        destinationAccount: Yup.string().required(tRequiredFieldError),
        currency: Yup.string().required(tRequiredFieldError),
        comment: Yup.string().when('options', {
            is: (options: string[]) => options.length > 0,
            then: Yup.string().required(String(i18n.t('required_field', tErrorsContext))),
            otherwise: Yup.string().notRequired(),
        }),
        amountToExtract: Yup.number()
            .required(tRequiredFieldError)
            .min(1, String(i18n.t('valid_amount', tErrorsContext)))
            .test(
                'max-available',
                String(i18n.t('error_amount_available', tErrorsContext)),
                function (value) {
                    const { currency } = this.parent;
                    const availableBalance = {
                        ARS: arsAvailable,
                        USD: usdAvailable,
                        USDC: usdCableAvailable,
                        USDM_T: usdmTAvailable,
                        USDC_EXT: usdcExtAvailable,
                        USDC_T: usdcTAvailable,
                    };
                    const balanceValue =
                        availableBalance[
                            normalizeCurrency(currency) as keyof typeof availableBalance
                        ] ?? 0;
                    return value !== undefined && value <= balanceValue;
                },
            ),
        otherwise: Yup.number().notRequired(),
    });

const WithdrawalModalContainer = (props: Props) => {
    const { close, setSnackBarMessage } = props;
    const { t } = useTranslation();
    const { data, isLoading } = useGetAccountStatus();
    const { customerCode, userData } = React.useContext(AuthContext);
    const { isLockedByUserType, lockedFunctionalities, marketsTimes } =
        React.useContext(MarketContext);

    const withdrawalsTimes = React.useMemo(() => {
        if (!marketsTimes) return null;
        return marketsTimes?.find(m => m.name === 'WITHDRAWAL') ?? null;
    }, [marketsTimes]);

    const isOutOfHours = React.useMemo(() => {
        return withdrawalsTimes
            ? isMarketClosed(
                  withdrawalsTimes?.t0OpenTime,
                  withdrawalsTimes?.t0CloseTime,
                  withdrawalsTimes.isMarketOpen,
              )
            : true;
    }, [withdrawalsTimes]);

    const filterQueryString = `&status=ACTIVE&customerCode=${customerCode}`;
    const { data: bankAccounts, isLoading: isBankAccountsLoading } = useGetBankAccounts({
        filterQueryString,
    } as GridHookParams);

    const {
        withdrawal,
        isLoadingWithdrawal,
        error: errorSubmit,
    } = useWithdrawal(setSnackBarMessage, close);

    const arsAvailable = data?.availableBalance.withdraw.ars;
    const usdAvailable = data?.availableBalance.withdraw.usd;
    const usdcTAvailable = data?.availableBalance.withdraw.usdcT;
    const usdmTAvailable = data?.availableBalance.withdraw.usdm;
    const usdcExtAvailable = data?.availableBalance.withdraw.usdcExt;
    const usdCableAvailable = data?.availableBalance.withdraw.usdc;

    const currencyOptions = Object.entries(data?.availableBalance.withdraw || {})
        .filter(([, available]) => available > 0)
        .map(([currency, available]) => {
            const formattedCurrency = t(`${currency}`) || currency.toUpperCase();
            return {
                id: formattedCurrency,
                name: formattedCurrency,
            };
        });

    const optionMapping: { [key: string]: string } = {
        [t('rescue_FCI')]: 'FCI_SELL',
        [t('no_money_available')]: 'NO_MONEY',
        [t('out_of_hours')]: 'OUT_OF_TIMEFRAME',
        [t('other')]: 'OTHER',
    };

    const handleSubmit = React.useCallback(async (values: FormikValues) => {}, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validationSchema: getValidationSchema(
                arsAvailable,
                usdAvailable,
                usdCableAvailable,
                usdmTAvailable,
                usdcExtAvailable,
                usdcTAvailable,
                bankAccounts,
                t,
            ),
            onSubmit: handleSubmit,
        }),
        [
            handleSubmit,
            arsAvailable,
            usdAvailable,
            usdCableAvailable,
            usdmTAvailable,
            usdcExtAvailable,
            usdcTAvailable,
            bankAccounts,
            t,
        ],
    );

    const formik = useFormik(formikInitProps);

    const handleCurrencyChange = (event: React.SyntheticEvent, newValue: any) => {
        formik.setFieldValue('currency', newValue?.id ?? '');
        formik.setFieldValue('options', []);
        formik.setFieldValue('comment', '');
        formik.setFieldValue('destinationAccount', '');
    };

    const checkboxOptions = React.useMemo(() => {
        if (formik.values.currency != '') {
            const baseOptions = ['no_money_available', 'other'];
            if (['ARS', 'USD', 'USDM T'].includes(formik.values.currency)) {
                baseOptions.unshift('rescue_FCI');
            }

            if (isOutOfHours) {
                baseOptions.splice(baseOptions.indexOf('other'), 0, 'out_of_hours');
            }
            return baseOptions.map(option => t(option));
        }
        return [];
    }, [formik.values.currency, isOutOfHours]);

    const bankAccountsOptions = React.useMemo(() => {
        if (!bankAccounts || !formik.values.currency) return [];
        const currency = formik.values.currency;
        if (currency === 'ARS') {
            return bankAccounts.items.filter(
                account =>
                    account.currency === 'ARS' && ['SAVINGS', 'CHECKING'].includes(account.type),
            );
        }
        if (currency === 'USD' || currency === 'USDM T') {
            return bankAccounts.items.filter(
                account =>
                    account.currency === 'USD' && ['SAVINGS', 'CHECKING'].includes(account.type),
            );
        }
        if (normalizeCurrency(currency) === 'USDC' || currency === 'USDC T') {
            return bankAccounts.items.filter(account => account.type === 'EXTERIOR');
        }
        if (currency === 'USDC Ext') {
            return bankAccounts.items.filter(account =>
                ['EXTERIOR', 'BROKER'].includes(account.type),
            );
        }
        return [];
    }, [bankAccounts, formik.values.currency]);

    const handleSend = async () => {
        formik.validateForm().then(errors => {
            if (Object.keys(errors).length === 0) {
                const withdrawalCondition = formik.values.options
                    .map((option: string) => optionMapping[option])
                    .filter(Boolean);

                const body = {
                    entryDate: new Date().toISOString(),
                    accountUuid: formik.values?.destinationAccount,
                    customerCode,
                    amount: formik.values.amountToExtract,
                    currency: normalizeCashflowCurrency(formik.values.currency),
                    withdrawalCondition,
                    comment: formik.values.comment,
                    otp: '',
                    operableUserUuid: userData?.id,
                    validateOtp: false,
                };
                if (customerCode) {
                    withdrawal({ ...body, customerCode });
                } else {
                    setSnackBarMessage(t('error_customer_code_missing'));
                }
            }
        });
    };

    const lockedMessage: string | null = React.useMemo(() => {
        if (!lockedFunctionalities) return null;
        if (!formik.values.destinationAccount) return null;
        const currency = normalizeCurrency(formik.values?.currency);

        const isLocked = isLockedByUserType(
            `WITHDRAWAL_${currency === 'USDC' ? 'USDC' : currency}` as LockedFunctionalityNames,
        );
        if (isLocked) {
            return (
                lockedFunctionalities.find(
                    l => l.name === `WITHDRAWAL_${currency === 'USDC' ? 'USDC' : currency}`,
                )?.details[0]?.lockReason ?? null
            );
        }
        return null;
    }, [lockedFunctionalities, isLockedByUserType, formik.values]);

    const childProps = {
        ...props,
        t,
        close,
        formik,
        bankAccountsOptions,
        isLoading,
        isBankAccountsLoading,
        availableExtraction: data?.availableBalance.withdraw,
        currencyOptions,
        checkboxOptions,
        handleCurrencyChange,
        isLoadingWithdrawal,
        errorSubmit,
        handleSend,
        lockedMessage,
        withdrawalsTimes,
        isOutOfHours,
    };

    return <WithdrawalModal {...childProps} />;
};

const propTypes = {};

interface extraProps {
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WithdrawalModalContainer.propTypes = propTypes;

export default WithdrawalModalContainer;
