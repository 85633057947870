import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './SearchClientModal.styled';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { MarketAccount } from 'types/api/marketAccount.types';

const SearchClientModal = (props: Props) => {
    const {
        t,
        handleConfirm,
        debouncedSearch,
        isOpen,
        close,
        isLoadingMarketAccounts,
        marketAccounts,
        errorMarketAccounts,
        selectedAccount,
        setSelectedAccount,
    } = props;

    return (
        <Dialog maxWidth="md" fullWidth open={isOpen} onClose={close}>
            <DialogTitle id="dialog-title" sx={{ pt: 2, pb: 1 }}>
                {t('search_client')}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        color: theme => theme.palette.grey[500],
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <St.BodyText variant="body2" paragraph>
                    {t('search_client_placeholder')}
                </St.BodyText>
                <CustomAutoComplete
                    id="client-search"
                    value={selectedAccount}
                    onChange={(event, value) => {
                        setSelectedAccount(value);
                    }}
                    options={marketAccounts ?? []}
                    label={t('search_client_label') as string}
                    placeholder={t('search_client_placeholder') as string}
                    filterSelectedOptions
                    isLoading={isLoadingMarketAccounts}
                    getOptionLabel={option =>
                        `${option.name} ${option.last_name} - N° ${option.customerCode}`
                    }
                    error={errorMarketAccounts as string}
                    onInputChange={(event, value) => debouncedSearch(value)}
                    renderOption={(props, option) => (
                        <Box {...props} key={option.id}>
                            {`${option.name} ${option.last_name} - N° ${option.customerCode}`}
                        </Box>
                    )}
                />
                <Box display="flex" justifyContent={'flex-end'} gap={2}>
                    <ActionButton size="small" color="primary" variant="outlined" onClick={close}>
                        {t('cancel')}
                    </ActionButton>
                    <ActionButton
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        disabled={selectedAccount === null}>
                        {t('confirm')}
                    </ActionButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    debouncedSearch: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    setSelectedAccount: PropTypes.func.isRequired,
    isLoadingMarketAccounts: PropTypes.bool.isRequired,
};

interface extraProps {
    isOpen: boolean;
    close(): void;
    marketAccounts: MarketAccount[] | null;
    errorMarketAccounts: string | null;
    selectedAccount: MarketAccount | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SearchClientModal.propTypes = propTypes;

export default SearchClientModal;
