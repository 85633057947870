import React from 'react';
import { tCommon, tErrorsContext } from 'constants/appConstants';
import TableOfTaker from './TableOfTaker';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { FormikContextType } from 'formik';
import { CollateralAvailableItem, GetCollateralOrderItem } from 'types/api/orders.types';
import { formatNumber } from 'utils/helpers/commonHelper';

const TableOfTakerContainer = (props: Props) => {
    const { collateralsAvailable, formik, row } = props;
    const [detailedInformation, setDetailedInformation] = React.useState({
        values: {},
        setFieldValue: () => {},
        errors: {},
    }); // In case it is detail
    const { setFieldValue, values, errors } = formik ?? detailedInformation;
    const { t } = useTranslation('market');
    const tBase = 'market_table_';
    const columns = [
        t(`${tBase}species`),
        ...(row ? [] : [t(`${tBase}quantity`)]),
        t(`${tBase}price_assessed`),
        ...(row ? [] : [t(`${tBase}amount_available`)]),
        t(`${tBase}warranty`),
        t('amount', tCommon),
    ];
    const bondMsj = (minimum: number, multiple: number) =>
        `${t('minimum_amount_of_guarantee', { ...tErrorsContext, minimum })}. ${t('multiple_amount_of_guarantee', { ...tErrorsContext, multiple })}`;

    React.useEffect(() => {
        if (row) {
            const obj: any = {};
            row.collaterals?.forEach(el => {
                const { price, quantity, ticker } = el;
                obj[`${ticker.ticker.replaceAll(' ', '_')}`] = {
                    id: ticker.id,
                    ticker: ticker.ticker,
                    availableQuantity: 0, // It is hidden in the detail
                    aforadoPrice: price, // How do I calculate? don't have any information
                    availableAmount: 0, // It is hidden in the detail
                    amount: price * quantity,
                    warranty: formatNumber(quantity),
                    price: 0, // It is hidden in the detail,
                };
            });
            return setDetailedInformation(prev => ({ ...prev, values: { tickers: obj } }));
        }
        if (collateralsAvailable && collateralsAvailable.length > 0 && formik) {
            collateralsAvailable.forEach(el => {
                const { availableQuantity, lastPrice, collateralInfo, type, step, minimum } = el;
                const aforadoPrice = parseFloat((lastPrice * collateralInfo.margin).toFixed(2));
                const availableAmount = availableQuantity * aforadoPrice;
                setFieldValue(`tickers[${el.tickers[0].ticker.replaceAll(' ', '_')}]`, {
                    id: el.tickers[0].id,
                    ticker: el.tickers[0].ticker,
                    availableQuantity,
                    aforadoPrice,
                    availableAmount,
                    amount: 0,
                    warranty: '0',
                    price: lastPrice,
                    type,
                    multiple: step,
                    msj: type === 'BOND' ? bondMsj(minimum, step) : undefined,
                    maximum: collateralInfo.maximum,
                    minimum,
                });
            });
        }
    }, []);

    const handleChange = (ticker: string, val: string) => {
        if (val === '') val = '0';
        const tickersCopy = { ...values.tickers };
        tickersCopy[ticker].warranty = parseFloat(val);
        const amount = tickersCopy[ticker].aforadoPrice * tickersCopy[ticker].warranty;
        const exceedsMaximum = amount > tickersCopy[ticker].maximum;
        const minimumToOperate = parseInt(val) < tickersCopy[ticker].minimum;
        const isBond = tickersCopy[ticker].type === 'BOND';
        const bondMessage = bondMsj(tickersCopy[ticker].minimum, tickersCopy[ticker].multiple);
        const maximumExcessMsj = t('amount_exceeds_the_maximum', {
            ...tErrorsContext,
            maximum: formatNumber(tickersCopy[ticker].maximum),
        });
        const minimumToOperateMsj = t('minimum_to_operate', {
            ...tErrorsContext,
            minimum: formatNumber(tickersCopy[ticker].minimum),
        });
        setFieldValue(`tickers[${ticker}]`, {
            ...tickersCopy[ticker],
            warranty: parseFloat(val),
            error: [0, '0'].includes(val)
                ? false
                : !(tickersCopy[ticker].warranty % tickersCopy[ticker].multiple === 0) ||
                  exceedsMaximum ||
                  minimumToOperate,
            amount,
            msj:
                exceedsMaximum || minimumToOperate
                    ? isBond
                        ? `${minimumToOperate ? '' : `${maximumExcessMsj}. `}${bondMessage}`
                        : minimumToOperate
                          ? minimumToOperateMsj
                          : maximumExcessMsj
                    : isBond
                      ? bondMessage
                      : undefined,
        });
    };

    React.useEffect(() => {
        if (values.tickers && !row) {
            let calculatedAmount = 0;
            Object.keys(values.tickers).forEach(key => {
                calculatedAmount += parseFloat(values.tickers[key].amount);
            });
            setFieldValue('calculatedAmount', calculatedAmount);
        }
    }, [values.tickers]);

    const childProps = {
        ...props,
        columns,
        handleChange,
        values,
        errors,
        t,
    };

    return <TableOfTaker {...childProps} />;
};

const propTypes = {};

interface extraProps {
    collateralsAvailable?: CollateralAvailableItem[];
    formik?: FormikContextType<any>;
    row?: GetCollateralOrderItem;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableOfTakerContainer.propTypes = propTypes;

export default TableOfTakerContainer;
