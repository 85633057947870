import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './AccountStatus.styled';
import { Grid } from '@mui/material';
import AccountInformation from './AccountInformation';
import CircularSpinner from '../CircularSpinner';
import {
    AccountAum,
    AccountPerformance,
    AvailableBalance,
    GetAccountStatusResponse,
    MultiCurrencyObj,
} from 'types/api/marketAccount.types';
import CustomPieChart from '../CustomPieChart';
import CustomRadioButton from 'components/common/CustomRadioButton';
import CustomIconCreator from '../CustomIconCreator';
import ClientInformation from './ClientInformation';
import MoneyIcon from 'assets/icons/money.svg?react';
import CurrencyLabel from '../CurrencyLabel';
import ActionButton from '../ActionButton';
import { channelRoutesPaths, mainRoutesPaths } from 'router/routesPaths';
import { tCommon } from 'constants/appConstants';
import { useNavigate } from 'react-router-dom';
import AumDetailModal from './AccountInformation/AumDetailModal';
import AvailableBalanceDetailModal from './AccountInformation/AvailableBalanceDetailModal';
import useBreakpoints from 'hooks/common/breakpoints.hooks';

const AccountStatus = (props: Props) => {
    const {
        isLoading,
        aum,
        availableBalance,
        performance,
        series,
        labels,
        total,
        isWallet,
        concertation,
        setConcertation,
        liquidation,
        setLiquidation,
        setSelectedInstrumentType,
        t,
        isUserApAc,
        data,
        detailedAum,
        openAumDetailModal,
        setOpenAumDetailModal,
        openAvailableBalanceDetailModal,
        setOpenAvailableBalanceDetailModal,
    } = props;

    const navigate = useNavigate();
    const { matchesMd } = useBreakpoints();

    return (
        <St.AccountStatusWrapper>
            <St.AccountStatusContainer>
                {isLoading ? (
                    <CircularSpinner />
                ) : (
                    <>
                        {isUserApAc ? null : (
                            <St.WalletSubtitle>
                                {t('account_information_wallet_subtitle')}
                            </St.WalletSubtitle>
                        )}
                        <Grid container gap={'80px'} alignItems="center">
                            <Grid item xs={12} lg={4}>
                                <AccountInformation
                                    aum={aum}
                                    performance={performance}
                                    isWallet={isWallet}
                                    isUserApAc={isUserApAc}
                                    setOpenAumDetailModal={setOpenAumDetailModal}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={7}
                                sx={{
                                    display: matchesMd ? 'flex' : '',
                                    justifyContent: matchesMd ? 'center' : 'flex-end',
                                    marginBottom: matchesMd ? '2.5rem' : '',
                                    marginTop: matchesMd ? '-5rem' : '',
                                    flexDirection: matchesMd && isUserApAc ? 'column' : 'row',
                                }}>
                                {isUserApAc && isWallet ? <ClientInformation /> : null}
                                {data?.aum.total.ars === 0 && data?.aum.total.ars === 0 ? (
                                    <St.NoDataChartContainer>
                                        <St.Amount typography="h5">0,00</St.Amount>
                                        <St.TotalText typography="body1">
                                            {t('total_pie_chart')}
                                        </St.TotalText>
                                    </St.NoDataChartContainer>
                                ) : (
                                    <CustomPieChart
                                        id="big-chart"
                                        series={series}
                                        labels={labels}
                                        width={isUserApAc ? '90%' : '100%'}
                                        total={total}
                                        setInstrumentType={setSelectedInstrumentType || undefined}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        {isWallet && (
                            <St.ModalTextContainer
                                onClick={() => setOpenAvailableBalanceDetailModal(true)}>
                                <St.InfoIcon />
                                <St.ModalText variant="caption">
                                    {t('see_available_detailed_text')}
                                </St.ModalText>
                            </St.ModalTextContainer>
                        )}

                        <Grid container gap={'40px'} alignItems="center">
                            <Grid item xs={12} lg={5}>
                                <St.AvailableBalanceContainer>
                                    <St.AccountInformationSubtitle variant="subtitle1">
                                        {t('account_information_available_balance_title')}
                                    </St.AccountInformationSubtitle>
                                    <St.MoneyLabelContainer>
                                        <CustomIconCreator
                                            icon={MoneyIcon}
                                            sx={{
                                                width: '1.7rem',
                                                height: '1.7rem',
                                                marginRight: '0.8rem',
                                                overflow: 'visible',
                                            }}
                                        />
                                        <CurrencyLabel
                                            variant="h5"
                                            currency="ARS"
                                            value={availableBalance?.operate.t1.ars ?? 0}
                                        />
                                    </St.MoneyLabelContainer>
                                    <St.MoneyLabelContainer>
                                        <CustomIconCreator
                                            icon={MoneyIcon}
                                            sx={{
                                                width: '1.7rem',
                                                height: '1.7rem',
                                                marginRight: '0.8rem',
                                                overflow: 'visible',
                                            }}
                                        />
                                        <CurrencyLabel
                                            variant="h5"
                                            currency="USD"
                                            value={availableBalance?.operate.t1.usd ?? 0}
                                        />
                                    </St.MoneyLabelContainer>
                                    <St.MoneyLabelContainer>
                                        {(!availableBalance ||
                                            availableBalance?.operate.t1.usdc > 0) && (
                                            <>
                                                <CustomIconCreator
                                                    icon={MoneyIcon}
                                                    sx={{
                                                        width: '1.7rem',
                                                        height: '1.7rem',
                                                        marginRight: '0.8rem',
                                                        overflow: 'visible',
                                                    }}
                                                />
                                                <CurrencyLabel
                                                    variant="h5"
                                                    currency="USD-C"
                                                    value={availableBalance?.operate.t1.usdc ?? 0}
                                                />
                                            </>
                                        )}
                                    </St.MoneyLabelContainer>
                                </St.AvailableBalanceContainer>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                {isWallet && (
                                    <St.AvailableBalanceContainer>
                                        <St.AccountInformationSubtitle variant="subtitle1">
                                            {t('account_information_withdraw_title')}
                                        </St.AccountInformationSubtitle>
                                        <St.MoneyLabelContainer>
                                            <CustomIconCreator
                                                icon={MoneyIcon}
                                                sx={{
                                                    width: '1.7rem',
                                                    height: '1.7rem',
                                                    marginRight: '0.8rem',
                                                    overflow: 'visible',
                                                }}
                                            />
                                            <CurrencyLabel
                                                variant="h5"
                                                currency="ARS"
                                                value={availableBalance?.withdraw.ars ?? 0}
                                            />
                                        </St.MoneyLabelContainer>
                                        <St.MoneyLabelContainer>
                                            <CustomIconCreator
                                                icon={MoneyIcon}
                                                sx={{
                                                    width: '1.7rem',
                                                    height: '1.7rem',
                                                    marginRight: '0.8rem',
                                                    overflow: 'visible',
                                                }}
                                            />
                                            <CurrencyLabel
                                                variant="h5"
                                                currency="USD"
                                                value={availableBalance?.withdraw.usd ?? 0}
                                            />
                                        </St.MoneyLabelContainer>
                                        <St.MoneyLabelContainer>
                                            {(!availableBalance ||
                                                availableBalance?.withdraw.usdc > 0) && (
                                                <>
                                                    <CustomIconCreator
                                                        icon={MoneyIcon}
                                                        sx={{
                                                            width: '1.7rem',
                                                            height: '1.7rem',
                                                            marginRight: '0.8rem',
                                                            overflow: 'visible',
                                                        }}
                                                    />
                                                    <CurrencyLabel
                                                        variant="h5"
                                                        currency="USD-C"
                                                        value={availableBalance?.withdraw.usdc ?? 0}
                                                    />
                                                </>
                                            )}
                                        </St.MoneyLabelContainer>
                                    </St.AvailableBalanceContainer>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container gap={'20px'} alignItems="center">
                            <Grid item xs={12} lg={5}>
                                {isWallet && (
                                    <>
                                        <St.WalletTitle variant="h6" color={'black'}>
                                            {t('wallet_title')}
                                        </St.WalletTitle>
                                        <St.RadioButtonContainer>
                                            <CustomRadioButton
                                                checked={concertation}
                                                name="wallet_concertation"
                                                onChange={() => {
                                                    setConcertation(true);
                                                    setLiquidation(false);
                                                }}
                                                text={t('wallet_concertation')}
                                                value="concertation"
                                            />
                                            <CustomRadioButton
                                                checked={liquidation}
                                                name="wallet_liquidation"
                                                onChange={() => {
                                                    setLiquidation(true);
                                                    setConcertation(false);
                                                }}
                                                text={t('wallet_liquidation')}
                                                value="liquidation"
                                            />
                                        </St.RadioButtonContainer>
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                {isWallet && (
                                    <>
                                        <St.FooterButtons>
                                            <ActionButton
                                                variant="contained"
                                                onClick={() =>
                                                    isUserApAc
                                                        ? navigate(
                                                              channelRoutesPaths.treasuryOrders,
                                                          )
                                                        : navigate(mainRoutesPaths.withdrawal)
                                                }>
                                                {t('extract', tCommon).toUpperCase()}
                                            </ActionButton>
                                            <ActionButton
                                                variant="outlined"
                                                onClick={() =>
                                                    isUserApAc
                                                        ? navigate(
                                                              channelRoutesPaths.treasuryOrders,
                                                              {
                                                                  state: {
                                                                      tab: 'deposits',
                                                                  },
                                                              },
                                                          )
                                                        : navigate(mainRoutesPaths.deposit)
                                                }>
                                                {t('deposit', tCommon).toUpperCase()}
                                            </ActionButton>
                                        </St.FooterButtons>
                                        <AumDetailModal
                                            aum={detailedAum}
                                            open={openAumDetailModal}
                                            close={() => setOpenAumDetailModal(false)}
                                        />
                                        <AvailableBalanceDetailModal
                                            open={openAvailableBalanceDetailModal}
                                            close={() => setOpenAvailableBalanceDetailModal(false)}
                                            availableBalance={availableBalance}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </St.AccountStatusContainer>
        </St.AccountStatusWrapper>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    total: PropTypes.string.isRequired,
    isWallet: PropTypes.bool,
    concertation: PropTypes.bool.isRequired,
    liquidation: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    openAumDetailModal: PropTypes.bool.isRequired,
    openAvailableBalanceDetailModal: PropTypes.bool.isRequired,
};

interface extraProps {
    aum?: AccountAum;
    availableBalance?: AvailableBalance;
    performance?: AccountPerformance;
    series: number[];
    labels: string[];
    setConcertation: (value: boolean) => void;
    setLiquidation: (value: boolean) => void;
    setSelectedInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
    data: GetAccountStatusResponse | undefined;
    isUserApAc: boolean;
    detailedAum?: MultiCurrencyObj;
    setOpenAumDetailModal: (value: boolean) => void;
    setOpenAvailableBalanceDetailModal: (value: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AccountStatus.propTypes = propTypes;

export default AccountStatus;
