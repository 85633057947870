import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TreasuryOrdersChannelPage from './TreasuryOrdersChannelPage';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useCustomFetch from 'hooks/common/CommonGrid/fetch.hooks';
import {
    CommonAction,
    CommonActionProps,
    CommonColumn,
    CommonFilter,
} from 'types/common/tableLayout.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { Box, Chip, Tooltip } from '@mui/material';
import {
    convertCurrencyFormat,
    getColorByStatus,
    getFullName,
    orderWithdrawalsOptions,
    scrollToTop,
} from 'utils/helpers/commonHelper';
import { useGetWithdrawals, useGetDeposits } from 'hooks/api/cahsflow.hooks';
import { GetDepositsItem, GetWithdrawalsItem } from 'types/api/cashflow.types';
import ViewDetailIcon from '../../../assets/icons/eye-icon.svg?react';
import DownloadIcon from '../../../assets/icons/download-line.svg?react';
import { MARKET_ACCOUNT_BASE_URL, tErrorsContext } from 'constants/appConstants';
import { useDownloadFile } from 'hooks/api/utils.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useLocation } from 'react-router-dom';
import CustomIconCreator from 'components/common/CustomIconCreator';
import AddIcon from 'assets/icons/add-line.svg?react';
import WithdrawalModal from './WithdrawalModal';
import DepositModal from './DepositModal';
import DetailWithdrawalModal from './DetailWithdrawalModal';
import moment from 'moment';

const TreasuryOrdersChannelPageContainer = (props: Props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState(location.state?.tab || 'withdrawals');
    const { downloadFile } = useDownloadFile();
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    const isWithdrawalsTab = currentTab === 'withdrawals';

    useEffect(() => {
        scrollToTop();
    }, []);

    const current = moment();
    const defaultFilters = [
        { query: 'dateFrom', queryValue: current.format('YYYY-MM-DD') },
        { query: 'dateTo', queryValue: current.format('YYYY-MM-DD') },
    ];

    const {
        data,
        loading,
        sortInfo,
        handleSort,
        handlePageChange,
        filterHeaderString,
        setFilterHeaderString,
    } = useCustomFetch({
        useGetData: isWithdrawalsTab ? useGetWithdrawals : useGetDeposits,
        defaultSortFieldId: 'createdAt',
        defaultFilters,
    });

    const handleTabChange = (newValue: string) => {
        setCurrentTab(newValue);
    };

    const handleDownloadClick = (row: GetDepositsItem) => {
        try {
            downloadFile(row?.fileUrl);
            setSnackBarMessage(t('successful_download'));
        } catch (error) {
            setSnackBarMessage(t('error_download', tErrorsContext), 'error');
        }
    };

    const commonColumns: CommonColumn<any>[] = [
        {
            id: 'entryDate',
            name: t('entry_date'),
            selector: row => formatDateWithHour(row.transferDate || row.entryDate),
            sortable: true,
        },
        {
            id: 'customerCode',
            name: t('customer_code'),
            selector: ({ user, customerCode }) => (
                <Tooltip title={getFullName(user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>{customerCode}</Box>
                </Tooltip>
            ),
            sortable: true,
        },
        {
            id: 'client',
            name: t('client'),
            selector: row => row.user.name + ' ' + row.user.lastName,
        },
    ];

    const withdrawalColumns: CommonColumn<GetWithdrawalsItem>[] = [
        ...commonColumns,
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.currency || '-',
            sortable: true,
        },
        {
            id: 'amount',
            name: t('amount'),
            selector: row => (row.amount ? convertCurrencyFormat(row.amount, row.currency) : '-'),
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
            sortable: true,
        },
        {
            id: 'type',
            name: t('type'),
            selector: ({ bankAccount }) => t(`bank_account_type_${bankAccount.type.toLowerCase()}`),
            sortable: true,
        },
    ];

    const depositColumns: CommonColumn<GetDepositsItem>[] = [
        ...commonColumns,
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.bankAccount?.currency || '-',
            sortable: true,
        },
        {
            id: 'amount',
            name: t('amount'),
            selector: row =>
                row.amount ? convertCurrencyFormat(row.amount, row.bankAccount?.currency) : '-',
        },
        {
            id: 'type',
            name: t('type'),
            selector: ({ bankAccount }) =>
                bankAccount.type === 'SAVINGS' || bankAccount.type === 'CHECKING'
                    ? t(`bank_account_type_local`)
                    : t(`bank_account_type_${bankAccount.type.toLocaleLowerCase()}`),
            sortable: true,
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'customerCode',
            label: t('customer_code'),
            type: 'requestAutocomplete',
            query: 'customerCode',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerCode',
                optionLabel: option => option.customerCode,
                keyTofilter: 'customerCode',
            },
        },
        {
            id: 'client',
            label: t('client'),
            type: 'requestAutocomplete',
            query: 'customerName',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerFullName',
                optionLabel: option => `${option.name} ${option.last_name}`,
                keyTofilter: 'name',
                extraFilter: '&sortField=name&sort=ASC',
            },
        },
        {
            id: 'dateFrom',
            label: t('from'),
            type: 'date',
            query: 'dateFrom',
            disableFutureDaysFromN: true,
        },
        {
            id: 'dateTo',
            label: t('to'),
            type: 'date',
            query: 'dateTo',
            disableFutureDaysFromN: true,
        },
    ];
    const withdrawalFilters: CommonFilter[] = [
        ...filters,
        {
            id: 'status',
            label: t('status'),
            type: 'select',
            query: 'status',
            options: orderWithdrawalsOptions,
        },
    ];

    const withdrawalsActionColumns: CommonAction<GetWithdrawalsItem>[] = [
        {
            id: 'detail',
            type: 'modal',
            Icon: (props: any) => <CustomIconCreator icon={ViewDetailIcon} />,
            onClick: (props: CommonActionProps) => <DetailWithdrawalModal {...props} />,
        },
    ];

    const depositActionColumns: CommonAction<GetDepositsItem>[] = [
        {
            id: 'download',
            Icon: (props: any) => <CustomIconCreator icon={DownloadIcon} />,
            onClick: (props: CommonActionProps) => {
                handleDownloadClick(props.row);
                return undefined;
            },
        },
    ];

    const headAction: CommonAction<GetWithdrawalsItem | GetDepositsItem> = {
        id: `new_order_${currentTab}`,
        Icon: () => <CustomIconCreator icon={AddIcon} sx={{ width: 18, height: 18, mt: 0.7 }} />,
        onClick: props =>
            currentTab === 'withdrawals' ? (
                <WithdrawalModal {...props} />
            ) : (
                <DepositModal {...props} />
            ),
    };

    const childProps = {
        t,
        currentTab,
        columns: isWithdrawalsTab ? withdrawalColumns : depositColumns,
        filters: isWithdrawalsTab ? withdrawalFilters : filters,
        rows: data,
        loading,
        sortInfo,
        filterHeaderString,
        setFilterHeaderString,
        handleTabChange,
        handleSort,
        handlePageChange,
        actionColumns: isWithdrawalsTab ? withdrawalsActionColumns : depositActionColumns,
        headAction,
        SnackBar,
    };

    return <TreasuryOrdersChannelPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TreasuryOrdersChannelPageContainer.propTypes = propTypes;

export default TreasuryOrdersChannelPageContainer;
