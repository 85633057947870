import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentModal from './InstrumentModal';
import { MarketContext } from 'context/market.context';
import { mainRoutesPaths } from 'router/routesPaths';
import { InstrumentFormattedFormModal } from 'types/api/instruments.types';
import { useGetInstrumentByTicker } from 'hooks/api/instruments.hooks';

const InstrumentModalContainer = (props: Props) => {
    const { t, setOpenModal, selectedInstrument } = props;
    const { isLockedByUserType } = React.useContext(MarketContext);
    const { instrument, isLoading } = useGetInstrumentByTicker(selectedInstrument?.ticker ?? '');
    const [term, setTerm] = React.useState({ id: 1, name: '24hs' });
    const [currency, setCurrency] = React.useState({ id: 1, name: 'ARS' });

    const handleClose = React.useCallback(() => {
        setOpenModal(false);
    }, [setOpenModal]);

    const handleTermChange = (event: React.SyntheticEvent, newValue: any) => {
        setTerm(newValue);
    };

    const handleCurrencyChange = (event: React.SyntheticEvent, newValue: any) => {
        setCurrency(newValue);
    };

    const handleInstrumentViewSheet = () => {
        localStorage.setItem('term', term.name);
        const selectedTicker =
            selectedInstrument?.type == 'FUND'
                ? selectedInstrument?.ticker
                : (instrument?.tickers.find(t => t.currency === currency.name)?.ticker ??
                  selectedInstrument?.ticker);

        window.location.replace(
            `${(selectedInstrument?.type === 'FUND' ? mainRoutesPaths.fundDetail : mainRoutesPaths.instrumentDetail).replace(':ticker', `${selectedTicker}`)}`,
        );
    };

    const childProps = {
        ...props,
        t,
        handleClose,
        term,
        currency,
        isLoading,
        handleTermChange,
        handleCurrencyChange,
        handleInstrumentViewSheet,
        isLockedByUserType,
        available: selectedInstrument?.available,
        usdTicker: instrument?.tickers.find(t => t.currency === 'USD')?.ticker,
        usdcTicker: instrument?.tickers.find(t => t.currency === 'USD-C')?.ticker,
        canShowValuation: ['EQUITY', 'CERTIFICATE', 'BOND', 'FUND'].includes(
            selectedInstrument?.type || '',
        ),
    };

    return <InstrumentModal {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
    selectedInstrument: InstrumentFormattedFormModal | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentModalContainer.propTypes = propTypes;

export default InstrumentModalContainer;
